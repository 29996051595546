import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useApi from "../../../services/auth/useApi";

export const viewNewFeatureApi = async (api: ReturnType<typeof useApi>): Promise<void> => {
  await api.post("/feature/check", {});
};

export const useViewNewFeatureMutation = (options?: UseMutationOptions<void, AxiosError>) => {
  const api = useApi();

  return useMutation<void, AxiosError>({
    mutationFn: () => viewNewFeatureApi(api),
    ...options,
  });
};
