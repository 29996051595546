import React from "react";
import { SideBarGroupSection } from "./SideBarGroupSection";
import { SideBarMenuButton } from "./SideBarMenuButton";
import { NewFeatureIcon, ProductFeedbackIcon, SettingsIcon, TrashIcon } from "../../../icons";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import { useRecoilState } from "recoil";
import { accountState } from "../../../recoil/account/accountStateV2";
import { useViewNewFeatureMutation } from "../../../react-query/space/core/useViewNewFeatureMutation";

interface SideBarActionSectionProps {
  isExpanded: boolean;
  onClickSettings: () => void;
  onClickTrash: () => void;
  trashToggleButtonRef: React.RefObject<HTMLButtonElement>;
}

export const SideBarActionSection: React.FC<SideBarActionSectionProps> = ({
  isExpanded,
  onClickSettings,
  onClickTrash,
  trashToggleButtonRef,
}) => {
  const [accountData, setAccountData] = useRecoilState(accountState);
  const trackCall = useJuneTrackCall();
  const hasNewFeature = accountData ? accountData.accountInfo.hasNewFeature : false;
  const { mutate: viewNewFeature } = useViewNewFeatureMutation();

  const handleClickFeedback = () => {
    trackCall("click_feedback");
    const url = "https://feedback.moba.works/";
    window.open(url, "_blank");
  };

  const handleClickNewFeature = () => {
    trackCall("click_update");
    const url = "https://feedback.moba.works/changelog";
    window.open(url, "_blank");
    viewNewFeature();
    if (accountData !== null) {
      setAccountData({
        ...accountData,
        accountInfo: {
          ...accountData.accountInfo,
          hasNewFeature: false,
        },
      });
    }
  };

  return (
    <SideBarGroupSection isExpanded={isExpanded} flex="0 0 auto">
      {hasNewFeature && (
        <SideBarMenuButton
          isExpanded={isExpanded}
          label={"New Feature"}
          icon={NewFeatureIcon}
          onClick={handleClickNewFeature}
          tooltipLabel={"New Feature"}
          isBrandColorHoverItem={true}
        />
      )}

      <SideBarMenuButton
        isExpanded={isExpanded}
        label={"Product Feedback"}
        icon={ProductFeedbackIcon}
        onClick={handleClickFeedback}
        tooltipLabel={"Product Feedback"}
        isBrandColorHoverItem={true}
      />

      <SideBarMenuButton
        isExpanded={isExpanded}
        label={"Settings"}
        icon={SettingsIcon}
        onClick={onClickSettings}
        tooltipLabel={"Settings"}
      />

      <SideBarMenuButton
        isExpanded={isExpanded}
        label={"Trash"}
        icon={TrashIcon}
        onClick={onClickTrash}
        ref={trashToggleButtonRef}
        tooltipLabel={"Trash"}
      />
    </SideBarGroupSection>
  );
};
