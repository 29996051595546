import React, { ReactNode } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";

interface SideBarGroupSectionProps extends FlexProps {
  children?: ReactNode;
  isExpanded?: boolean;
  isScrolling?: boolean;
}
export const SideBarGroupSection: React.FC<SideBarGroupSectionProps> = ({
  isExpanded = true,
  isScrolling = false,
  children,
  ...props
}) => {
  return (
    <Flex
      w={"full"}
      flexDir={"column"}
      alignItems={isExpanded ? "normal" : "flex-start"}
      py={2.5}
      px={isExpanded ? 2 : 2.5}
      pr={isExpanded && isScrolling ? 0 : undefined}
      gap={1.5}
      overflowX={"hidden"}
      {...props}
    >
      {children}
    </Flex>
  );
};
