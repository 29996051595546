import { useRecoilValue } from "recoil";
import { selectedSpaceIdListStateSelector } from "../../recoil/spaces/selectedSpaceIdListState";
import { Flex, Text } from "@chakra-ui/react";
import { pxToRem } from "../../utils/styles/size";
import { hexToRgba } from "../../utils/styles/color";
import { SpaceCircle } from "./SideBarMenu/SpaceCircle";
import React from "react";
import { DEFAULT_SPACE_COLOR } from "../../constants/space";
const { useDragLayer } = require("react-dnd");

function FilterProjectReorderDragLayer() {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListStateSelector);

  if (!item || !isDragging || item.dragType !== "filterProject") {
    return null;
  }

  const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 1000,
    left: 0,
    top: 0,
    transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
    width: "256px",
    height: "40px",
    borderRadius: "6px",
    border: "1px solid var(--hover, rgba(255, 255, 255, 0.08))",
    background:
      "linear-gradient(0deg, var(--hover-big, rgba(255, 255, 255, 0.04)) 0%, var(--hover-big, rgba(255, 255, 255, 0.04)) 100%), #363A3A",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    boxSizing: "border-box",
    cursor: "pointer",
  };

  const isSelected = selectedSpaceIdList.includes(item.data.id);

  return (
    <div style={layerStyles} key={{ title: "project title" }}>
      <Flex justifyContent={"center"} alignItems={"center"} opacity={1} cursor={"pointer"}>
        <Flex
          w={"full"}
          h={pxToRem(36)}
          py={1}
          px={0.5}
          borderRadius="md"
          _hover={{
            bg: hexToRgba("#ffffff", 0.04),
          }}
          bg={"initial"}
          border={"none"}
          alignItems={"center"}
          cursor={"pointer"}
        >
          <SpaceCircle
            spaceColor={item?.data?.color ?? DEFAULT_SPACE_COLOR}
            isSelected={isSelected}
          />

          <Text textStyle={"body1"} color={"#D9D9D9"} noOfLines={1} ml={2}>
            {item?.data?.title ?? "No Space"}
          </Text>
        </Flex>
      </Flex>
    </div>
  );
}

export default FilterProjectReorderDragLayer;
