import { Spacer, Flex, Text } from "@chakra-ui/react";
import { MONTH_NAMES, WEEK_DAYS } from "../../../../constants/utils/utils";
import { pxToRem } from "../../../../utils/styles/size";
import React from "react";

interface MonthProps {
  data: string;
}

export const Month: React.FC<MonthProps> = ({ data }) => {
  const date = new Date(data);
  const today = new Date();

  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();
  const todayDay = today.getUTCDate();
  const todayMonth = today.getUTCMonth();
  const todayYear = today.getUTCFullYear();

  const weekdayAbbreviation = WEEK_DAYS[date.getUTCDay()];
  const monthName = MONTH_NAMES[date.getUTCMonth()];
  const isToday = day === todayDay && month === todayMonth && year === todayYear;

  return (
    <Flex role={"group"} flex={1} maxW={pxToRem(360)} alignItems="center" gap={1.5}>
      <Text textStyle={"label1"} color={"white"}>
        {day}
      </Text>
      <Text textStyle={"label4"} color={"white"}>
        {weekdayAbbreviation}
      </Text>
      <Text textStyle={"label4"} color={"#7C7D7D"}>
        {monthName}
      </Text>
      {isToday && (
        <Text textStyle={"label3"} color="#69e4ff">
          today
        </Text>
      )}

      <Spacer />

      <Text
        display={"none"}
        _groupHover={{
          display: "block",
        }}
        textStyle={"body3"}
        color="#7c7d7d"
      >
        {year}
      </Text>
    </Flex>
  );
};
