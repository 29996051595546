import styles from "../Wiki.module.css";
import emptyIcon from "../../../assets/Wiki/icon-empty.svg";
import emptyAddIcon from "../../../assets/Wiki/icon-empty-add.png";

export function ProejctListEmpty({ onClick, isDone }) {
  return (
    <div className={styles.project__empty__body} onClick={() => onClick(true, null)}>
      {isDone ? (
        <>
          <img className={styles.empty__icon} src={emptyIcon} alt="emptyIcon" />
          <span className={styles.empty__description}>No archived space</span>
        </>
      ) : (
        <div className={styles.empty__div} onClick={() => onClick(true, null)}>
          <img className={styles.empty__icon} src={emptyAddIcon} alt="emptyAddIcon" />
          <span className={styles.empty__description}>Create your first space!</span>
        </div>
      )}
    </div>
  );
}
