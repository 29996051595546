import React from "react";
import { Button, Text, Tooltip } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";

interface ShowAllMinButtonProps {
  onClick: () => void;
}
export const ShowAllMinButton: React.FC<ShowAllMinButtonProps> = ({ onClick }) => {
  return (
    <Tooltip label={"show all"} placement={"right"}>
      <Button
        boxSizing={"border-box"}
        role={"group"}
        bg={hexToRgba("#ffffff", 0.08)}
        border={"1px solid"}
        borderColor={hexToRgba("#ffffff", 0.08)}
        _hover={{
          bg: hexToRgba("#ffffff", 0.16),
        }}
        _focus={{
          outline: "none",
          boxShadow: "none",
        }}
        minW={"initial"}
        w={pxToRem(28)}
        px={0}
        h={pxToRem(28)}
        _active={{}}
        onClick={onClick}
        borderRadius={pxToRem(5)}
      >
        <Text
          textStyle={"body3"}
          color={"#D9D9D9"}
          _groupHover={{
            color: "white",
          }}
        >
          All
        </Text>
      </Button>
    </Tooltip>
  );
};
