export const convertGoogleCalendarToMobaCalendar = (eventData) => {
  const {
    id,
    creator,
    summary,
    title,
    start,
    end,
    attendees,
    hangoutLink,
    extendedProperties,
    projectId,
    integration,
    kind,
    recurrence,
    note,
    links,
    itemStatus,
    visibility,
    transparency,
    isCreateSelectEvent,
    isDataDuplicateEvent,
    taskType,
    created,
    eventType,
    recurringEventId,
    recurringNoteId,
    recurringThisEdited,
  } = eventData;

  const getTime = (time) => new Date(time);
  const getDate = (date) => date;

  const convertStart = start.dateTime
    ? getTime(start.dateTime)
    : start.date
      ? getDate(start.date)
      : start || null;

  const convertEnd = end.dateTime
    ? getTime(end.dateTime)
    : end.date
      ? getDate(end.date)
      : end || null;

  const privateProps = extendedProperties?.private || {};
  const parsedIntegration = privateProps.integration
    ? JSON.parse(privateProps.integration)
    : integration;

  const convertedProjectId =
    (privateProps.projectIdSync
      ? JSON.parse(privateProps.projectIdSync).projectId
      : privateProps.projectId) ||
    projectId ||
    null;

  const convertedLinks =
    Array.isArray(links) && links.length > 0
      ? links
      : privateProps.linksSync
        ? JSON.parse(privateProps.linksSync).links
        : privateProps.links
          ? JSON.parse(privateProps.links)
          : [];

  const taskTypeFromServer = privateProps.taskTypeSync
    ? JSON.parse(privateProps.taskTypeSync).taskType
    : privateProps.taskType;

  const convertedTaskType = taskTypeFromServer
    ? taskTypeFromServer
    : taskType
      ? taskType
      : created && new Date(created) > new Date("2024-08-19")
        ? eventType === "default"
          ? "Event"
          : "Task"
        : attendees
          ? attendees.length > 0
            ? "Event"
            : "Task"
          : "Task";

  return {
    id,
    creator: creator?.email || creator,
    title: summary || title,
    start: convertStart,
    startTimeZone: start.timeZone || eventData.startTimeZone || null,
    end: convertEnd,
    endTimeZone: start.timeZone || eventData.startTimeZone || null,
    allDay:
      // allday가 있으면 있는 값으로 설정
      typeof eventData.allDay !== "undefined"
        ? eventData.allDay
        : // allday가 없으면 start.timeZone 또는 eventData.startTimeZone이 있으면 false, 없으면 true
          typeof start.timeZone !== "undefined" || typeof eventData.startTimeZone !== "undefined"
          ? false
          : true,
    attendees,
    hangoutLink: hangoutLink || null,
    projectId: convertedProjectId,
    integration: parsedIntegration,
    kind,
    recurrence: recurrence || [],
    recurringEventId: recurringEventId || null,
    recurringNoteId: recurringNoteId || null,
    note,
    links: convertedLinks,
    itemStatus: itemStatus || privateProps.itemStatus,
    link: eventData.link || null,
    visibility: visibility || null,
    transparency: transparency || null,
    isCreateSelectEvent: !!isCreateSelectEvent,
    isDataDuplicateEvent,
    taskType: convertedTaskType,
    recurringThisEdited: recurringThisEdited ? true : false,
  };
};
