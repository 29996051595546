import React, { forwardRef } from "react";
import {
  Flex,
  Text,
  Icon,
  ComponentWithAs,
  IconProps,
  IconButton,
  Box,
  Tooltip,
  IconButtonProps,
} from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";

interface SideBarMenuButtonProps extends IconProps {
  isExpanded?: boolean;
  isSelected?: boolean;
  label: string;
  tooltipLabel?: string;
  icon: ComponentWithAs<"svg", IconProps>;
  onClick?: () => void;
  isBrandColorHoverItem?: boolean;
}

export const SideBarMenuButton = forwardRef<HTMLButtonElement, SideBarMenuButtonProps>(
  (
    {
      isExpanded,
      isSelected,
      label,
      icon,
      onClick,
      isBrandColorHoverItem = false,
      tooltipLabel,
      ...props
    },
    ref
  ) => {
    const hoverStyles = { color: isBrandColorHoverItem || isSelected ? "#69E4FF" : "white" };
    if (!isExpanded) {
      return (
        <Tooltip label={tooltipLabel} placement={"right"} isDisabled={!tooltipLabel}>
          <IconButton
            ref={ref}
            role={"group"}
            my={pxToRem(4)}
            aria-label={label}
            onClick={onClick}
            variant="outline"
            fontSize={pxToRem(18)}
            borderRadius={pxToRem(5)}
            border={"1px solid"}
            borderColor={hexToRgba("#ffffff", 0.08)}
            minWidth={"initial"}
            bg={hexToRgba("#ffffff", 0.08)}
            w={pxToRem(28)}
            h={pxToRem(28)}
            _hover={{
              bg: hexToRgba("#ffffff", 0.16),
              transition: "all 0.3s ease",
            }}
            boxSizing={"border-box"}
            _active={{}}
            icon={
              <Icon
                as={icon}
                color={isSelected ? "#69E4FF" : "#D9D9D9"}
                _groupHover={hoverStyles}
                transition="transform 0.3s ease, color 0.3s ease"
                {...props}
              />
            }
            _focus={{
              outline: "none",
              boxShadow: "none",
            }}
          />
        </Tooltip>
      );
    }

    return (
      <Flex
        h={pxToRem(36)}
        px={0.5}
        borderRadius={5}
        cursor="pointer"
        alignItems={"center"}
        as={"button"}
        onClick={onClick}
        ref={ref}
        _focus={{
          outline: "none",
          boxShadow: "none",
        }}
        _hover={{
          bg: hexToRgba("#ffffff", 0.04),
          transition: "background-color 0.3s ease",
        }}
        role={"group"}
      >
        <Box
          border={"1px solid"}
          borderColor={"#ffffff14"}
          borderRadius={pxToRem(5)}
          p={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg={hexToRgba("#ffffff", 0.08)}
          _groupHover={{
            bg: hexToRgba("#ffffff", 0.16),
            transition: "background-color 0.3s ease",
          }}
        >
          <Icon
            as={icon}
            color={isSelected ? "#69E4FF" : "#D9D9D9"}
            w={pxToRem(18)}
            h={pxToRem(18)}
            _groupHover={hoverStyles}
            transition="transform 0.3s ease, color 0.3s ease"
            {...props}
          />
        </Box>
        <Text textStyle={"body1"} color={isSelected ? "#69E4FF" : "#D9D9D9"} noOfLines={1} ml={2.5}>
          {label}
        </Text>
      </Flex>
    );
  }
);
