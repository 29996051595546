import { Box, Center, Circle } from "@chakra-ui/react";
import React from "react";

export function SpaceColorCircle(props: {
  colorValue: string;
  spaceColor: string;
  onClick: () => void;
}) {
  const isSelected = props.colorValue.toLowerCase() === props.spaceColor.toLowerCase();

  return (
    <Center position="relative" width={3.5} height={3.5}>
      {isSelected ? (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          borderRadius="50%"
          border="1px solid white"
          backgroundColor="transparent"
          display="flex"
          alignItems="center"
          justifyContent="center"
          _hover={{
            border: "2px solid gray",
          }}
          cursor={"pointer"}
          onClick={props.onClick}
        >
          <Circle size={2.5} backgroundColor={props.colorValue} />
        </Box>
      ) : (
        <Circle
          size={2.5}
          bg={props.colorValue}
          border={props.colorValue === props.spaceColor ? "1px solid #ffffff" : "none"}
          onClick={props.onClick}
          boxSizing={"content-box"}
          cursor={"pointer"}
          _hover={{
            border: "2px solid gray",
          }}
        />
      )}
    </Center>
  );
}
