import React from "react";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { AddIcon } from "../../../icons";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";

interface SideBarSpaceAddButtonProps {
  onClick: () => void;
  isInputVisible: boolean;
}
export const SideBarSpaceAddButton: React.FC<SideBarSpaceAddButtonProps> = ({
  onClick,
  isInputVisible,
}) => {
  return (
    <Tooltip label={"Add a space"} placement={"right"}>
      <IconButton
        icon={
          <AddIcon
            color={"#D9D9D9"}
            _groupHover={{
              color: "white",
            }}
          />
        }
        role={"group"}
        aria-label={"Add Space"}
        onClick={onClick}
        variant="outline"
        fontSize={pxToRem(16)}
        borderRadius={pxToRem(5)}
        border={"1px solid"}
        borderColor={"#ffffff14"}
        minWidth={"initial"}
        boxSize={pxToRem(26)}
        _hover={{
          bg: hexToRgba("#ffffff", 0.08),
        }}
        {...{
          bg: isInputVisible ? hexToRgba("#ffffff", 0.08) : "transparent",
        }}
        _focus={{
          outline: "none",
          boxShadow: "none",
        }}
        _active={{}}
      />
    </Tooltip>
  );
};
