export const textStylesTheme = {
  title1: {
    fontSize: "1.75rem", // 28px
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  title2: {
    fontSize: "1.25rem", // 20px
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  label1: {
    fontFamily: "Montserrat Variable, sans-serif",
    fontSize: "1.5rem", // 24px
    fontWeight: 600,
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  label2: {
    fontFamily: "Montserrat Variable, sans-serif",
    fontSize: "1rem", // 16px
    fontWeight: 600,
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  label3: {
    fontFamily: "Montserrat Variable, sans-serif",
    fontSize: "0.8125rem", // 13px
    fontWeight: 500,
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  label4: {
    fontFamily: "Montserrat Variable, sans-serif",
    fontSize: "0.75rem", // 12px
    fontWeight: 600,
    lineHeight: 1.25,
    letterSpacing: 0,
  },
  body1: {
    fontSize: "0.875rem", // 14px
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  body2: {
    fontSize: "0.8125rem", // 13px
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  body3: {
    fontSize: "0.75rem", // 12px
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  tooltip: {
    fontSize: "0.6875rem", // 11px
    fontWeight: 500,
    lineHeight: 1.3,
    letterSpacing: 0,
  },
  caption1: {
    fontSize: "0.6875rem", // 11px
    fontWeight: 400,
    lineHeight: 1.3,
    letterSpacing: 0,
  },
  caption2: {
    fontSize: "0.625rem", // 10px
    fontWeight: 400,
    lineHeight: 1.3,
    letterSpacing: 0,
  },
};
