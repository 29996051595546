import useApi from "../../../services/auth/useApi";
import { useEffect, useState } from "react";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import { useRecoilState } from "recoil";
import { projectListState } from "../../../recoil/projects/projectListState";
import styles from "../ProjectCreate.module.css";
import ToolTip from "../Tooltip";
import bookmarkDefaultImg from "../../../assets/Wiki/project-detail-bookmark-add.png";
import ResourceAddModal from "../../../views/TaskDetail/Link/LinkAddModal";

import { AutoExpandingTextarea } from "./AutoExpandingTextarea";

export function ProjectInfo({ data, loadData }) {
  const api = useApi();

  const [visibleTooltipIndex, setVisibleTooltipIndex] = useState(null);
  const [iconPos, setIconPos] = useState(-100);
  const [projectInfo, setProjectInfo] = useState({
    createdAt: null,
    creator: null,
    id: null,
    title: null,
    projectId: null,
    description: null,
    bookmarks: [],
  });
  const [isResourceAddModal, setIsResourceAddModal] = useState(false);
  const trackCall = useJuneTrackCall();
  const [projectList, updateProjectList] = useRecoilState(projectListState);

  useEffect(() => {
    if (data) {
      setProjectInfo({
        createdAt: data.createdAt || null,
        creator: data.creator || null,
        id: data.id || null,
        title: data.title || null,
        projectId: data.projectId || null,
        description: data.description || null,
        bookmarks: data.bookmarks || null,
      });
    }
  }, [data]);

  const handleDataChange = (field, value) => {
    const newData = { [field]: value };

    updateProjectList((current) => {
      const updatedList = current.map((project) => {
        if (project.id === projectInfo.id) {
          return { ...project, [field]: value };
        }
        return project;
      });
      return updatedList;
    });

    api
      .patch("projects/" + projectInfo.id, newData)
      .then((res) => {
        if (field === "title") {
          trackCall("update_space", { type: "title", location: "wiki" });
        } else if (field === "description" && projectInfo.description !== value) {
          // NOTE 값이 변경될 때에만 동작하도록 설정
          trackCall("update_space", {
            type: "description",
            location: "wiki",
          });
        }
      })
      .catch((error) => {
        loadData(true, true, true);
      });
  };

  const handleResourceAdd = () => {
    setIsResourceAddModal(true);
  };

  const updateBookmark = (key, value) => {
    trackCall("update_space", { type: "knowledge", location: "wiki" });

    if (key === "links") {
      const newItem = {
        title: value.name,
        kind: key,
        data: {
          link: value.url,
        },
      };

      api
        .post(`projects/${projectInfo.id}/bookmarks`, newItem)
        .then((res) => {
          const transformedData = {
            key: res.data.bookmarkId,
            title: res.data.title,
            data: res.data.data,
            kind: res.data.kind,
          };

          const updatedBookmarks = Array.isArray(projectInfo.bookmarks)
            ? [...projectInfo.bookmarks, transformedData]
            : [transformedData];

          setProjectInfo({
            ...projectInfo,
            bookmarks: updatedBookmarks,
          });

          updateProjectList((current) => {
            return current.map((project) => {
              if (project.id === projectInfo.id) {
                return {
                  ...project,
                  bookmarks: updatedBookmarks,
                };
              }
              return project;
            });
          });
        })
        .catch((error) => {
          loadData(true, true, true);
        });
    }
  };

  const handleBlur = (event) => {
    handleDataChange("title", event.target.value);
  };

  const onChange = (event) => {
    setProjectInfo((current) => ({
      ...current,
      title: event.target.value,
    }));
  };

  const handleIconEnter = (index) => (e) => {
    const rect = e.target.getBoundingClientRect();
    const centerY = rect.top + rect.height / 2;
    setVisibleTooltipIndex(index);
    setIconPos(centerY);
  };

  const handleIconLeave = (e) => {
    setVisibleTooltipIndex(null);
    setIconPos(-100);
  };

  const handleMove = (url) => () => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      window.open(url, "_blank");
    } else {
      window.open("https://www." + url, "_blank");
    }
  };

  const handleDeleteLink = (item) => {
    api
      .delete(`projects/${data.id}/bookmarks/${item.key}`)
      .then(() => {
        loadData();
      })
      .catch((error) => {
        loadData(true, true, true);
      });

    const newBookmarks = new Map(projectInfo.bookmarks.map((bm) => [bm.key, bm]));
    newBookmarks.delete(item.key);
    const updatedBookmarks = Array.from(newBookmarks.values());
    setProjectInfo({ ...projectInfo, bookmarks: updatedBookmarks });

    updateProjectList((current) => {
      return current.map((project) => {
        if (project.id === projectInfo.id) {
          return { ...project, bookmarks: newBookmarks };
        }
        return project;
      });
    });
  };

  return (
    <>
      <div className={styles.project__info}>
        <input
          type="text"
          className={styles.projectUntitle}
          placeholder="Untitled Project"
          value={projectInfo?.title}
          onChange={onChange}
          onBlur={handleBlur}
        />

        <AutoExpandingTextarea
          field="description"
          dataChange={handleDataChange}
          description={projectInfo?.description}
        />
        <div className={styles.project__line}></div>
        <span className={styles.bookmark__title}>Bookmarks</span>

        <div className={styles["setting-property-resource"]}>
          {projectInfo &&
            projectInfo.bookmarks &&
            projectInfo.bookmarks.map((item, index) => (
              <div
                key={index}
                className={styles["resource-item"]}
                onMouseEnter={handleIconEnter(index)}
                onMouseLeave={handleIconLeave}
                onClick={handleMove(item.data?.link)}
              >
                <div
                  className={styles["resource-item-favicon"]}
                  style={{
                    backgroundImage:
                      "url(https://www.google.com/s2/favicons?sz=64&domain_url=" +
                      item.data?.link +
                      ")",
                  }}
                ></div>
                <div
                  className={styles["resource-item-delete"]}
                  style={{
                    visibility: `${visibleTooltipIndex === index ? "visible" : "hidden"}`,
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteLink(item);
                  }}
                ></div>
                <ToolTip
                  key={index}
                  text={item.title}
                  isVisible={visibleTooltipIndex === index}
                  style={{ top: `${iconPos}px` }}
                />
              </div>
            ))}
          <div className={styles["resource-item"]} onClick={handleResourceAdd}>
            <img className={styles.bookmark__default} src={bookmarkDefaultImg} />
          </div>
        </div>
      </div>
      {isResourceAddModal && (
        <ResourceAddModal
          onSave={(key, value) => updateBookmark(key, value)}
          onClose={() => setIsResourceAddModal(false)}
        />
      )}
    </>
  );
}
