import { atom, selectorFamily } from "recoil";
import { DividerType, NoSpaceType, SpaceType } from "../../types/space";
import { selector } from "recoil";
import {
  getInProgressSpaceList,
  insertNoSpaceFilter,
  isDividerType,
} from "../../services/space/space.service";

/**
 * Inprogress || Completed || Delete 된 프로젝트의 리스트 atom
 */

/* atom */
export const projectListState = atom<(SpaceType | DividerType | NoSpaceType)[] | null>({
  key: "projectListState",
  default: null,
});

/* in progress selector */
export const inProgressItemsState = selector({
  key: "inProgressSpacesState",
  get: ({ get }) => {
    const projectList = get(projectListState) || [];
    return getInProgressSpaceList(projectList);
  },
});

/* space + no space */
export const spacesWithoutDividerState = selector({
  key: "spacesWithoutDividerState",
  get: ({ get }) => {
    const projectList = get(projectListState) || [];
    return projectList.filter((space) => !isDividerType(space));
  },
});

/* (space + no space) * (in progress)*/
export const inProgressAllSpaceState = selectorFamily({
  key: "inProgressAllSpaceStateSelector",
  get:
    (order: string[]) =>
    ({ get }) => {
      const inProgressItems = get(inProgressItemsState);
      return insertNoSpaceFilter(order, inProgressItems);
    },
});
