import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { CreateSpaceType, SpaceType, UpdateSpaceType } from "../../../types/space";
import { spaceQueryKey } from "./useFetchSpaceQuery";
import { projectListState } from "../../../recoil/projects/projectListState";
import { useRecoilState } from "recoil";
import { isSpaceType } from "../../../services/space/space.service";

export const patchSpaceApi = async (
  api: ReturnType<typeof useApi>,
  projectId: string,
  payload: Partial<CreateSpaceType>
): Promise<void> => {
  await api.patch(`/projects/${projectId}`, payload);
};

export const useUpdateSpaceMutation = (
  options?: UseMutationOptions<void, AxiosError, { id: string; payload: Partial<UpdateSpaceType> }>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [projectList, setProjectList] = useRecoilState(projectListState);

  return useMutation<void, AxiosError, { id: string; payload: Partial<UpdateSpaceType> }>({
    mutationFn: ({ id, payload }) => patchSpaceApi(api, id, payload),
    onMutate: async ({ id, payload }) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKey() });

      if (projectList) {
        const updatedProjectList = projectList.map((project) => {
          if (!isSpaceType(project)) return project;
          return project.id === id ? { ...project, ...payload } : project;
        });

        setProjectList(updatedProjectList);
      }

      return { projectList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
