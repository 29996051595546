import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKey } from "../core/useFetchSpaceQuery";
import { useRecoilState } from "recoil";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";
import { projectListState } from "../../../recoil/projects/projectListState";
import { ProjectStatus } from "../../../types/space";
import { useJuneTrackCall } from "../../../utils/june/analytics";

export const restoreTrashSpaceApi = async (
  api: ReturnType<typeof useApi>,
  projectId: string
): Promise<void> => {
  await api.patch(`/projects/${projectId}/unmark`);
};

export const useRestoreTrashedSpaceMutation = (
  options?: UseMutationOptions<void, AxiosError, string>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [selectedSpaces, setSelectedSpaces] = useRecoilState(selectedSpaceIdListState);
  const [projectList, setProjectList] = useRecoilState(projectListState);
  const trackCall = useJuneTrackCall();

  return useMutation<void, AxiosError, string>({
    mutationFn: (projectId: string) => restoreTrashSpaceApi(api, projectId),
    onMutate: async (projectId) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKey() });

      const updatedSpaces = selectedSpaces ? [...selectedSpaces, projectId] : [projectId];
      setSelectedSpaces(updatedSpaces);

      if (projectList) {
        const updatedProjectList = projectList.map((project) =>
          project.id === projectId ? { ...project, status: ProjectStatus.InProgress } : project
        );
        setProjectList(updatedProjectList);
      }

      return { projectId, previousSelectedSpaces: selectedSpaces };
    },

    onSuccess: () => {
      trackCall("restore_space", { type: "deleted_items" });
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
