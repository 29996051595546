import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKey } from "../core/useFetchSpaceQuery";
import { useRecoilState } from "recoil";
import { projectListState } from "../../../recoil/projects/projectListState";
import { ProjectStatus } from "../../../types/space";

export const markSpaceDoneApi = async (
  api: ReturnType<typeof useApi>,
  projectId: string
): Promise<void> => {
  await api.patch(`/projects/${projectId}/done`);
};

export const useMarkSpaceDoneMutation = (
  options?: UseMutationOptions<void, AxiosError, string>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [projectList, setProjectList] = useRecoilState(projectListState);

  return useMutation<void, AxiosError, string>({
    mutationFn: (projectId: string) => markSpaceDoneApi(api, projectId),
    onMutate: async (projectId) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKey() });

      if (projectList) {
        const updatedProjectList = projectList.map((project) =>
          project.id === projectId ? { ...project, status: ProjectStatus.Completed } : project
        );
        setProjectList(updatedProjectList);
      }

      return { projectId, previousProjectList: projectList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
