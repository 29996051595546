export const textToRRule = (value) => {
  const textToRecurrence = {
    "Every Day": "RRULE:FREQ=DAILY",
    "Every Weekday": "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR",
    "Every Week": "RRULE:FREQ=WEEKLY",
    "Every Month": "RRULE:FREQ=MONTHLY",
    "Every Year": "RRULE:FREQ=YEARLY",
  };

  return textToRecurrence[value];
};

export const rRuleToText = (value) => {
  const stringValue = value[0];
  const recurrenceToText = {
    "RRULE:FREQ=DAILY": "Every Day",
    "RRULE:FREQ=WEEKLY": "Every Week",
    "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR": "Every Weekday",
    "RRULE:FREQ=MONTHLY": "Every Month",
    "RRULE:FREQ=YEARLY": "Every Year",
  };

  if (recurrenceToText[stringValue]) {
    return recurrenceToText[stringValue];
  }

  // ;UNTIL이 있는 것 포함되도록 예외처리
  const untilPattern = /^(RRULE:FREQ=(DAILY|WEEKLY|MONTHLY|YEARLY)(;BYDAY=[A-Z,]*)?);UNTIL=/;
  const match = stringValue.match(untilPattern);
  if (match) {
    const baseRule = match[1];
    if (recurrenceToText[baseRule]) {
      return recurrenceToText[baseRule];
    }
  }

  // 일치하는 규칙이 없으면 null 반환
  return "Custom Repeat";
};
