import { RRule, rrulestr, datetime } from "rrule";
import moment from "moment-timezone";

const generateInstanceId = (start, isAllday, recurringEventId) => {
  if (!start) {
    console.error("instanceStart가 없습니다.");
    return null;
  }

  const startTimeConvertToDateType = start instanceof Date ? start : new Date(start);

  const convertedDateTimeSuffix = isAllday
    ? moment(startTimeConvertToDateType).format("YYYYMMDD")
    : moment.tz(startTimeConvertToDateType, "utc").format("YYYYMMDDTHHmmss[Z]"); // Z는 시간대 정보 포함

  // 최종 ID 생성
  return `${recurringEventId}_${convertedDateTimeSuffix}`;
};

export const generateRecurringEventId = (baseRecurringEvent) => {
  // baseRecurringEvent: id or id_R{yyyyMMdd} 형태
  // 따라서 id_R{yyyyMMdd} 형태의 경우 id 만 남기도록 수정
  let baseRecurringEventId = baseRecurringEvent.id;
  if (baseRecurringEventId.includes("_")) {
    baseRecurringEventId = baseRecurringEventId.split("_")[0];
  }
  return baseRecurringEventId;
};

// 반복 이벤트를 개별 인스턴스로 확장
export const expandRecurringEvent = (event, calendarViewBaseDate) => {
  const isAllDay = event.start.timeZone ? false : true;

  let rruleString = event.recurrence ? event.recurrence[0] : null;
  const rule = rrulestr(rruleString);

  let recurringEndDateTime = new Date(
    calendarViewBaseDate.getFullYear(),
    calendarViewBaseDate.getMonth() + 2,
    0
  );

  // NOTE Timezone 처리
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const eventTimeZone = event.start.timeZone || browserTimeZone;

  const untilDateTimeInLocal = rule.options.until
    ? moment.tz(rule.options.until, eventTimeZone).toDate()
    : moment.tz(recurringEndDateTime, eventTimeZone).toDate();

  if (!rruleString) return []; // RRULE이 없으면 빈 배열 반환

  // allDay일때 확장
  if (isAllDay) {
    // NOTE 시작, 종료 시간 처리
    const recurringBaseEventStartDate = new Date(event.start.date);
    const recurringBaseEventEndDate = new Date(event.end.date);
    const dayDiff =
      (recurringBaseEventEndDate.getTime() - recurringBaseEventStartDate.getTime()) /
      (1000 * 60 * 60 * 24);

    // NOTE 반복 값 처리
    const updatedRRule = new RRule({
      ...rule.origOptions,
      dtstart: datetime(
        recurringBaseEventStartDate.getFullYear(),
        recurringBaseEventStartDate.getMonth() + 1,
        recurringBaseEventStartDate.getDate(),
        0,
        0
      ),
      tzid: eventTimeZone,
      until: datetime(
        untilDateTimeInLocal.getFullYear(),
        untilDateTimeInLocal.getMonth() + 1,
        untilDateTimeInLocal.getDate(),
        0,
        0
      ),
    });

    let recurredDates = updatedRRule.all();

    const finalizedRecurredDates = recurredDates.map((rawNewStartDate) => {
      const newRecurringEventId = generateRecurringEventId(event);
      const newId = generateInstanceId(rawNewStartDate, true, newRecurringEventId);
      const newEndDate = moment(rawNewStartDate).add(dayDiff, "days").toDate();

      return {
        ...event,
        id: newId,
        start: {
          date: moment(rawNewStartDate).format("YYYY-MM-DD"),
        },
        end: {
          date: moment(newEndDate).format("YYYY-MM-DD"),
        },
        recurrence: event.recurrence,
        recurringEventId: newRecurringEventId,
        recurringNoteId: event.id,
        iCalUID: event.iCalUID,
      };
    });

    return finalizedRecurredDates;
  }

  try {
    // NOTE 시작, 종료 시간 처리
    const recurringBaseEventStartDateTime = new Date(event.start.dateTime);
    const recurringBaseEventEndDateTime = new Date(event.end.dateTime);
    const timeDifference =
      recurringBaseEventEndDateTime.getTime() - recurringBaseEventStartDateTime.getTime(); // 밀리초 단위

    // NOTE Timezone 처리
    const utcOffsetMinutes = recurringBaseEventStartDateTime.getTimezoneOffset();
    const utcOffsetHours = -utcOffsetMinutes / 60;

    // NOTE 반복 값 처리
    const updatedRRule = new RRule({
      ...rule.origOptions,
      // NOTE datetime으로 넣으면 로컬 시간 +utcOffset 시간으로 반복
      dtstart: datetime(
        recurringBaseEventStartDateTime.getFullYear(),
        recurringBaseEventStartDateTime.getMonth() + 1,
        recurringBaseEventStartDateTime.getDate(),
        recurringBaseEventStartDateTime.getHours(),
        recurringBaseEventStartDateTime.getMinutes()
      ),
      tzid: eventTimeZone,
      until: datetime(
        untilDateTimeInLocal.getFullYear(),
        untilDateTimeInLocal.getMonth() + 1,
        untilDateTimeInLocal.getDate(),
        untilDateTimeInLocal.getHours(),
        untilDateTimeInLocal.getMinutes()
      ),
    });

    let recurredDates = updatedRRule.all();

    const finalizedRecurredDateTimes = recurredDates.map((rawNewStartTime) => {
      const tunedNewStartTimeInLocal = moment
        .tz(rawNewStartTime, eventTimeZone)
        .subtract(utcOffsetHours, "hours")
        .toDate();

      const newRecurringEventId = generateRecurringEventId(event);
      const newId = generateInstanceId(tunedNewStartTimeInLocal, false, newRecurringEventId);
      const newEndTimeInLocal = new Date(tunedNewStartTimeInLocal.getTime() + timeDifference);

      return {
        ...event,
        id: newId,
        start: {
          ...event.start,
          dateTime: tunedNewStartTimeInLocal,
        },
        end: {
          ...event.end,
          dateTime: newEndTimeInLocal,
        },
        recurrence: event.recurrence,
        recurringEventId: newRecurringEventId,
        recurringNoteId: event.id,
        iCalUID: event.iCalUID,
      };
    });

    return finalizedRecurredDateTimes;
  } catch (error) {
    console.error("RRULE 처리 중 오류 발생:", error);
    return [];
  }
};
