import { NoSpaceType } from "../../types/space";

export const DEFAULT_SPACE_COLOR = "#abadad";

export const NoSpaceFilter = "No Space";

export const noSpaceFilterItem: NoSpaceType = {
  id: NoSpaceFilter,
  color: DEFAULT_SPACE_COLOR,
};
