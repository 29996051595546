import { extendTheme } from "@chakra-ui/react";
import { fontsTheme } from "./fonts";
import { textStylesTheme } from "./textStyles";
import { tooltipTheme } from "./Tooltip";

const customTheme = extendTheme({
  fonts: fontsTheme,
  textStyles: textStylesTheme,
  components: {
    Tooltip: tooltipTheme,
  },
});

export default customTheme;
