import { atom } from "recoil";

export const recurringPopupState = atom({
  key: "recurringPopupState",
  default: {
    type: "", // edit, delete
    headerTitle: "",
    isVisible: false,
    clickType: "", // ok, cancel, discard
    messageType: "", // current(this), from(this and following), all
    okButtonTitle: "",
    closeButtonTitle: "",
    discardButtonTitle: "",
    isDataDuplicateWithGuest: false,
    isAllEnabled: true,
    isCurrentOptionEnabled: true,
    data: null,
    resolve: null, // Promise resolve 함수
    reject: null, // Promise reject 함수
  },
});
