import { DividerType } from "../../../types/space";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import {
  inProgressAllSpaceState,
  projectListState,
} from "../../../recoil/projects/projectListState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKey } from "../core/useFetchSpaceQuery";
import { useFetchProjectOrderQuery } from "../core/useFetchProjectOrderQuery";
import { createDividerApi } from "./useCreateDividerMutation";
import { useReorderSpacesMutation } from "../core/useReorderSpaceMutation";

interface DuplicateDividerPayload {
  targetId: string;
  newDivider: DividerType;
}

export const useDuplicateDividerMutation = (
  options?: UseMutationOptions<DividerType, AxiosError, DuplicateDividerPayload>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { data: projectOrderData = [] } = useFetchProjectOrderQuery();
  const projectList = useRecoilValue(inProgressAllSpaceState(projectOrderData));
  const setProjectList = useSetRecoilState(projectListState);
  const { mutate: reorderMutate } = useReorderSpacesMutation();

  return useMutation<DividerType, AxiosError, DuplicateDividerPayload>({
    mutationFn: async ({ newDivider }: DuplicateDividerPayload) => {
      return createDividerApi(api, newDivider);
    },
    onMutate: async ({ targetId, newDivider }: { targetId: string; newDivider: DividerType }) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKey() });

      let previousList = [...(projectList || [])];
      const targetIndex = previousList.findIndex((item) => item.id === targetId);

      if (targetIndex === -1) {
        previousList = [...(projectList || []), newDivider];
      }

      const updatedList = [
        ...previousList.slice(0, targetIndex + 1),
        newDivider,
        ...previousList.slice(targetIndex + 1),
      ];

      reorderMutate({
        projectIds: updatedList.map((project) => project.id),
      });

      setProjectList(updatedList);

      return { previousList };
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
