import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKey } from "../core/useFetchSpaceQuery";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";
import { NoSpaceFilter } from "../../../constants/space";
import { inboxTaskListState } from "../../../recoil/taskList/inboxTaskListState";
import { TaskType } from "../../../types/task";
import { projectListState } from "../../../recoil/projects/projectListState";
import { DividerType, ProjectStatus, SpaceType } from "../../../types/space";
import { isDividerType, isNoSpaceType } from "../../../services/space/space.service";
import { accountState } from "../../../recoil/account/accountStateV2";

export const trashSpaceApi = async (
  api: ReturnType<typeof useApi>,
  projectId: string,
  email: string
): Promise<void> => {
  await api.patch(`/projects/${projectId}/mark`, null, {
    headers: {
      "X-Requester": email,
    },
  });
};

export const useMoveToTrashSpaceMutation = (
  options?: UseMutationOptions<void, AxiosError, string>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [selectedSpaces, setSelectedSpaces] = useRecoilState(selectedSpaceIdListState);
  const [inboxTaskList, updateInboxTaskList] = useRecoilState<null | TaskType[]>(
    inboxTaskListState
  );
  const [projectList, setProjectList] = useRecoilState(projectListState);
  const accountData = useRecoilValue(accountState);
  const email = accountData?.accountInfo.accounts[0]?.email ?? "";

  return useMutation<void, AxiosError, string>({
    mutationFn: (projectId: string) => trashSpaceApi(api, projectId, email),
    onMutate: async (projectId) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKey() });

      const updatedSpaces = selectedSpaces
        ? selectedSpaces.filter((spaceId) => spaceId !== projectId)
        : [];

      if (updatedSpaces.includes(NoSpaceFilter)) {
        setSelectedSpaces(updatedSpaces);
      } else {
        setSelectedSpaces([...updatedSpaces, NoSpaceFilter]);
      }

      if (inboxTaskList) {
        const updatedInboxTaskList = inboxTaskList.map((task) =>
          task.projectId === projectId ? { ...task, projectId: undefined } : task
        );
        updateInboxTaskList(updatedInboxTaskList);
      }

      if (projectList) {
        const updatedProjectList = projectList.map((project) => {
          if (isDividerType(project)) return project;
          if (isNoSpaceType(project)) return project;
          return project.id === projectId ? { ...project, status: ProjectStatus.Deleted } : project;
        });
        setProjectList(updatedProjectList);
      }

      return {
        projectId,
        previousSelectedSpaces: selectedSpaces,
        previousInboxTaskList: inboxTaskList,
      };
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
