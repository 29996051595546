import {
  DividerType,
  NoSpaceType,
  ProjectCategory,
  ProjectStatus,
  SpaceType,
} from "../../types/space";
import { v4 as uuid4 } from "uuid";
import { PROJECT_LIST_COLOR } from "../../constants";
import { DEFAULT_SPACE_COLOR, NoSpaceFilter, noSpaceFilterItem } from "../../constants/space";

export const getInProgressSpaceList = (projects: (SpaceType | DividerType | NoSpaceType)[]) => {
  return projects.filter((project) => {
    if (!isSpaceType(project)) return true;
    return project.status === ProjectStatus.InProgress;
  });
};

export const getDeletedSpaceList = (projects: (SpaceType | DividerType | NoSpaceType)[]) => {
  return projects.filter((project) => {
    if (isDividerType(project) || isNoSpaceType(project)) return false;
    return project.status === ProjectStatus.Deleted;
  });
};

export const createProjectPayload = (
  email: string,
  newTitle: string,
  projectList: (SpaceType | DividerType | NoSpaceType)[] | null
) => {
  return {
    id: `${email}-${uuid4()}`,
    creator: email,
    title: newTitle,
    color: getNextAvailableSpaceColor(projectList),
    status: ProjectStatus.InProgress,
  };
};

export const createDividerPayload = (email: string): DividerType => {
  return {
    id: `${email}-project-divider-${uuid4()}`,
    creator: email,
    category: ProjectCategory.ProjectDivider,
  };
};

export const isNoSpaceType = (
  item: SpaceType | NoSpaceType | DividerType | undefined | null
): item is NoSpaceType => {
  return !!item && "id" in item && item.id === NoSpaceFilter;
};

export const isDividerType = (
  item: SpaceType | NoSpaceType | DividerType | undefined | null
): item is DividerType => {
  return !!item && "category" in item && item.category === ProjectCategory.ProjectDivider;
};

export const isSpaceType = (
  item: SpaceType | NoSpaceType | DividerType | undefined | null
): item is SpaceType => {
  return (
    !!item &&
    typeof item === "object" &&
    "id" in item &&
    "title" in item &&
    "status" in item &&
    "creator" in item
  );
};

export const insertNoSpaceFilter = (
  orderedSpaceIds: string[],
  currentSpaces: (SpaceType | DividerType | NoSpaceType)[]
): (NoSpaceType | SpaceType | DividerType)[] => {
  if (currentSpaces.map((space) => space.id).includes(NoSpaceFilter)) {
    return currentSpaces;
  }

  const noSpaceFilterIndex = orderedSpaceIds.indexOf(NoSpaceFilter);

  // 기획 : no-space-filter의 order를 변경한 적이 없는 경우 항상 맨 뒤에
  if (noSpaceFilterIndex === -1) {
    return [...currentSpaces, noSpaceFilterItem];
  }

  if (currentSpaces.length === 0) {
    return [noSpaceFilterItem];
  }
  const currentSpaceIdsSet = new Set(currentSpaces.map((space) => space.id));
  const unorderedSpaces = currentSpaces.filter((space) => !orderedSpaceIds.includes(space.id));

  const orderedSpaces = [];
  let inserted = false;

  for (const id of orderedSpaceIds) {
    if (id === NoSpaceFilter) {
      orderedSpaces.push(noSpaceFilterItem);
      inserted = true;
    }
    if (currentSpaceIdsSet.has(id)) {
      const foundSpace = currentSpaces.find((space) => space.id === id);
      if (foundSpace) {
        orderedSpaces.push(foundSpace);
      }
    }
  }

  if (!inserted) {
    orderedSpaces.push(noSpaceFilterItem);
  }

  return [...unorderedSpaces, ...orderedSpaces];
};

// 기획 : no space를 제외한 1개의 space만 선택된 경우 projectId를 삽입
export const extractSpaceId = (spaceIdList: string[] | null) => {
  if (!spaceIdList) return {};

  return spaceIdList.length === 1 && spaceIdList[0] !== NoSpaceFilter
    ? { projectId: spaceIdList[0] }
    : {};
};

export const getAllSpaceIds = (
  projectList: (SpaceType | DividerType | NoSpaceType)[]
): (string | typeof NoSpaceFilter)[] => {
  return projectList.filter((space) => !isDividerType(space)).map((space) => space.id);
};

export const isSpaceInProgress = (space: SpaceType): boolean => {
  return space.status === "InProgress";
};

export const getActiveSpaceIds = (
  selectedSpaceIds: (string | typeof NoSpaceFilter)[],
  projectList: (SpaceType | DividerType | NoSpaceType)[]
): Set<string | typeof NoSpaceFilter> => {
  const activeSpaceIds = new Set<string | typeof NoSpaceFilter>([]);

  selectedSpaceIds.forEach((id) => {
    if (id === NoSpaceFilter) {
      activeSpaceIds.add(id);
    } else {
      const space = projectList.find((project) => project.id === id);
      if (space && !isDividerType(space) && !isNoSpaceType(space) && isSpaceInProgress(space)) {
        activeSpaceIds.add(id);
      }
    }
  });
  return activeSpaceIds;
};

export const addSelectedSpace = (
  currentSpaces: string[] | null,
  newProjectId?: string
): string[] => {
  const projectIdToAdd = newProjectId || NoSpaceFilter;

  if (!currentSpaces) {
    return [projectIdToAdd];
  }

  if (!currentSpaces.includes(projectIdToAdd)) {
    return [...currentSpaces, projectIdToAdd];
  }

  return currentSpaces;
};

export const getSpaceColor = (
  spaceList: SpaceType[],
  spaceId?: string,
  fallbackColor?: string | null
) => {
  const fallbackColorToUse = !!fallbackColor ? fallbackColor : DEFAULT_SPACE_COLOR;
  if (!spaceId) return fallbackColorToUse;
  const space = spaceList?.find((space) => space.id === spaceId);
  return space ? space.color : fallbackColorToUse;
};

export const getUsedColors = (
  spaces: (SpaceType | DividerType | NoSpaceType)[] | null
): string[] => {
  if (!spaces) return [];
  return spaces.map((space) => space?.color).filter((color): color is string => !!color);
};

export const getNextAvailableSpaceColor = (
  spaces: (SpaceType | DividerType | NoSpaceType)[] | null
): string => {
  const assignedColors = getUsedColors(spaces);
  const unusedColors = PROJECT_LIST_COLOR.filter((color) => !assignedColors.includes(color));

  if (unusedColors.length > 0) {
    return unusedColors[0];
  }

  const nextIndex = assignedColors.length % PROJECT_LIST_COLOR.length;
  return PROJECT_LIST_COLOR[nextIndex];
};

export const isNonDeletedSpace = (space: SpaceType | DividerType | NoSpaceType) => {
  return (
    isSpaceType(space) &&
    (space.status === ProjectStatus.InProgress || space.status === ProjectStatus.Completed)
  );
};
