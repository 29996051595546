import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import useApi from "../../services/auth/useApi";
import useApiCalendar from "../../services/calendar/useApiCalendar";
import styles from "./ProjectCreate.module.css";
import { v4 as uuid4 } from "uuid";
import projectBackIcon from "../../assets/Wiki/project-back-icon.png";
import { projectListState } from "../../recoil/projects/projectListState";
import { useJuneTrackCall } from "../../utils/june/analytics";
import { TimeLine } from "./Timeline/TimelineSection/TimelineSection";
import { ProjectInfo } from "./Project/ProjectInfo";
import { accountState } from "../../recoil/account/accountStateV2";
import { selectedSpaceIdListState } from "../../recoil/spaces/selectedSpaceIdListState";
import { getNextAvailableSpaceColor } from "../../services/space/space.service";

export default function ProjectCreate({ onDismissClick, loadData, projectDetail }) {
  const api = useApi();
  const [projectInfo, setProjectInfo] = useState(projectDetail);
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [dotColor, setDotColor] = useState("");
  const [selectedSpaces, setSelectedSpaces] = useRecoilState(selectedSpaceIdListState);
  const [calendarTaskEvent, setCalendarTaskEvent] = useState(null);
  const [projectList, updateProjectList] = useRecoilState(projectListState);

  const calendarApi = useApiCalendar();
  const trackCall = useJuneTrackCall();

  const handleAddProject = () => {
    const newItem = {
      id: `${accountData.accountInfo.accounts[0].email}-${uuid4()}`,
      creator: `${accountData.accountInfo.accounts[0].email}`,
      title: "Untitled Project",
      color: getNextAvailableSpaceColor(projectList),
      status: "InProgress",
      description: "",
    };

    updateProjectList((current) => {
      return [newItem, ...current];
    });

    if (selectedSpaces) {
      setSelectedSpaces([...selectedSpaces, newItem.id]);
    } else {
      setSelectedSpaces([newItem.id]);
    }

    api
      .post("projects", newItem)
      .then((res) => {
        setProjectInfo(res.data);
        setDotColor(res.data.color);
      })
      .catch((error) => {
        loadData(true, true, true);
      });
  };

  useEffect(() => {
    if (projectInfo === null) {
      handleAddProject();
    } else {
      trackCall("view_space_detail");
      setProjectInfo(projectDetail);
      setDotColor(projectDetail.color);
      taskLoadData();
    }
  }, []);

  const taskLoadData = async () => {
    try {
      const refreshRes = await api.post("auth/oauth2/refresh", {
        kind: "account",
        key: projectDetail.creator,
      });
      const calendarRes = await calendarApi.get("", {
        headers: {
          Authorization: `Bearer ${refreshRes.data.accessToken}`,
        },
        params: {
          privateExtendedProperty: `projectId=${projectInfo.id}`,
          maxResults: 2500,
          singleEvents: true,
        },
      });

      const eventsByDate = {};

      calendarRes.data.items.forEach((event) => {
        let startDateTime;
        let endDateTime;
        let isAllDay = false;

        // 종일 이벤트 확인 (start와 end가 date 형식인 경우)
        if (event.start.date && event.end.date) {
          startDateTime = new Date(event.start.date);
          endDateTime = new Date(event.end.date);
          isAllDay = true;
        } else {
          // 시간 지정 이벤트
          startDateTime = new Date(event.start.dateTime);
          endDateTime = new Date(event.end.dateTime);
          isAllDay = endDateTime - startDateTime >= 86400000;
        }

        if (!isAllDay) {
          // 하루 이내 이벤트
          const dateKey = startDateTime.toISOString().split("T")[0];
          if (!eventsByDate[dateKey]) {
            eventsByDate[dateKey] = [];
          }
          eventsByDate[dateKey].push({ ...event, isAllDay });
        } else {
          // 종일 또는 여러 날에 걸친 이벤트
          let currentDate = new Date(startDateTime);
          while (currentDate < endDateTime) {
            const dateKey = currentDate.toISOString().split("T")[0];
            if (!eventsByDate[dateKey]) {
              eventsByDate[dateKey] = [];
            }
            eventsByDate[dateKey].push({ ...event, isAllDay });
            currentDate.setDate(currentDate.getDate() + 1);
          }
        }
      });

      Object.keys(eventsByDate).forEach((date) => {
        eventsByDate[date].sort(
          (a, b) =>
            new Date(a.start.dateTime || a.start.date) - new Date(b.start.dateTime || b.start.date)
        );
      });

      setCalendarTaskEvent(eventsByDate);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.project__create__area}>
      <div className={styles.header} onClick={() => onDismissClick()}>
        <img className={styles.header__button} src={projectBackIcon} />
      </div>
      <div className={styles.project__body}>
        {<ProjectInfo key="proejctInfo" data={projectInfo} loadData={loadData} />}
        {<TimeLine key="timeLine" dotColor={dotColor} calendarTaskEvent={calendarTaskEvent} />}
      </div>
    </div>
  );
}
