import styles from "../Wiki.module.css";
import ProjectItem from "../ProjectItem";

export function ProjectList({ projectData, onClick, loadData }) {
  return (
    <div className={styles.project__body}>
      <div className={styles.project__container}>
        {projectData &&
          projectData?.map((project, index) => {
            return (
              <ProjectItem
                key={index}
                projectRowData={project}
                onClick={onClick}
                loadData={loadData}
              />
            );
          })}
      </div>
    </div>
  );
}
