import React, { useEffect, useState } from "react";
import { Box, Center, Divider, Flex, Tooltip } from "@chakra-ui/react";
import { hexToRgba } from "../../../utils/styles/color";
import { SpaceMenuItemPopover } from "./SpaceMenuItemPopover";
import { DividerType } from "../../../types/space";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";
import { createPortal } from "react-dom";
import FilterProjectReorderDragLayer from "../FilterProjectReorderDragLayer";
import { FilterDividerReorderDragLayer } from "../FilterDividerReorderDragLayer";

interface SpaceDividerItemProps {
  data: DividerType;
  onDelete: () => void;
  onDuplicate: () => void;
  isExpanded: boolean;
  index: number;
  onReorder: (orderId: string[]) => void;
}

export const SpaceDividerItem: React.FC<SpaceDividerItemProps> = ({
  onDelete,
  data,
  onDuplicate,
  isExpanded,
  index,
  onReorder,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [prevIndex, setPrevIndex] = useState(index);
  const handleMenuClose = () => setMenuVisible(false);
  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setMenuVisible(true);
  };

  const { ref, isDragging, shouldShowPreview } = useDragAndDrop({
    index,
    data,
    onReorder,
    prevIndex,
    setPrevIndex,
  });

  return (
    <Box
      borderRadius={shouldShowPreview ? "6px" : "initial"}
      bg={shouldShowPreview ? "rgba(255, 255, 255, 0.04)" : "initial"}
      w={"full"}
    >
      <Flex ref={ref} opacity={shouldShowPreview ? 0 : 1} cursor={"pointer"}>
        <SpaceMenuItemPopover
          menuVisible={menuVisible}
          handleMenuClose={handleMenuClose}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          deleteLabel={"Delete divider"}
          duplicateLabel={"Duplicate divider"}
          offset={[15, -15]}
        >
          <Center
            w={"full"}
            cursor={"pointer"}
            py={2}
            px={0.5}
            borderRadius={6}
            _hover={{
              bg: hexToRgba("#ffffff", 0.04),
            }}
            onContextMenu={handleRightClick}
          >
            <Divider color={hexToRgba("#ffffff", 0.24)} />
          </Center>
        </SpaceMenuItemPopover>
      </Flex>
      {shouldShowPreview &&
        createPortal(
          <div style={{ cursor: "pointer" }}>
            <FilterDividerReorderDragLayer />
          </div>,
          document.body
        )}
    </Box>
  );
};
