import { CreateDividerType, DividerType } from "../../../types/space";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { projectListState } from "../../../recoil/projects/projectListState";
import { useRecoilState } from "recoil";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKey } from "../core/useFetchSpaceQuery";

export const createDividerApi = async (
  api: ReturnType<typeof useApi>,
  payload: CreateDividerType
): Promise<DividerType> => {
  const response = await api.post("/project/dividers", payload); // Adjust the endpoint if needed
  return response.data;
};

export const useCreateDividerMutation = (
  options?: UseMutationOptions<DividerType, AxiosError, CreateDividerType>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [projectList, setProjectList] = useRecoilState(projectListState);

  return useMutation<DividerType, AxiosError, CreateDividerType>({
    mutationFn: (payload) => createDividerApi(api, payload),
    onMutate: async (newDivider) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKey() });
      const newSpaces = projectList ? [newDivider, ...projectList] : [newDivider];
      setProjectList(newSpaces);
      return { projectList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
