import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../ProjectCreate.module.css";

export function AutoExpandingTextarea({ field, dataChange, description }) {
  const [text, setText] = useState("");
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    adjustHeight();
  }, [text]);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.minHeight = "0px";
    if (textarea.scrollHeight > 126) {
      textarea.style.minHeight = `${textarea.scrollHeight}px`;
    } else {
      textarea.style.minHeight = "126px";
    }
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  useEffect(() => {
    if (description) {
      setText(description);
    }
  }, [description]);

  return (
    <textarea
      ref={textareaRef}
      value={text}
      onInput={handleInputChange}
      onBlur={(event) => dataChange(field, event.target.value)}
      className={styles.project__description}
      placeholder="Please give a brief space introduction."
    />
  );
}
