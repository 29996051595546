import { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";
import { isNoteDataChanged } from "../../utils/taskDetail/checkDataChanged/isNoteDataChanged";
import { areDateEqual } from "../../utils/common/dateTime/areDateEqual";
import { areDateTimeEqual } from "../../utils/common/dateTime/areDateTimeEqual";
import typeForVisibility from "../../utils/taskDetail/visibility/typeForVisibility";
import formatDateTimeForJune from "../../utils/june/formatDateTimeForJune";
import { handlePopupKeyEvent } from "../../utils/taskDetail/handlePopupKeyEvent";

import useFetchMeetingCode from "../../queries/TaskDetail/useFetchMeetingCode";
import { useJuneTrackChanges } from "../../hooks/useJuneTrackChanges";
import { useDeleteRecurrenceBlock } from "../../queries/RecurrenceBlock";
import useFetchCalendarEvents from "../../hooks/useFetchCalendarEvents";
import { useUpdateRecurrenceBlock } from "../../queries/useUpdateRecurrenceBlock";

import { meetWithAccountsState } from "../../recoil/account/accountState";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { toastState } from "../../recoil/toast/toastState";
import { guestPopupState } from "../../recoil/popup/guestPopupState";
import { recurringPopupState } from "../../recoil/popup/recurringPopupState";
import {
  calendarEventDuplicateItemState,
  calendarViewBaseDateState,
  saveEventState,
} from "../../recoil/calendar/calendarState";
import { inboxTaskListState } from "../../recoil/taskList/inboxTaskListState";
import { doneTaskListState } from "../../recoil/taskList/doneTaskListState";
import { visibilityState } from "../../recoil/calendar/settingCalendar";
import { isFirstCreatedState } from "../../recoil/taskDetail/isFirstCreateState";
import { taskDetailHeightState } from "../../recoil/taskDetail/taskDetailHeightState";

import Header from "./Header/Header";
import TaskSetting from "./TaskSetting";
import RadioBtn from "../../compoenets/RadioBtn";

import { useOpenRecurringPopup } from "../../hooks/useOpenRecurringPopup";
import { useOpenGuestPopup } from "../../hooks/useOpenGuestPopup";

import { COMPLETED, INPROGRESS } from "../../constants/taskStateType";

import clsx from "clsx";

import styles from "./style.module.css";
import { accountState } from "../../recoil/account/accountStateV2";
import { addSelectedSpace } from "../../services/space/space.service";
import { selectedSpaceIdListState } from "../../recoil/spaces/selectedSpaceIdListState";
import { deleteNullValueInObject } from "../../utils/taskDetail/formatServerSendData";
import {
  isAttendeesChanged,
  shouldNotifyGuests,
} from "../../utils/taskDetail/checkDataChanged/checkDataChanged";

const AccModal = ({ creator, onClose }) => {
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const { data } = taskDetail;
  const modalRef = useRef(null);

  const handleRadioBtn = (selectedAcc) => {
    if (data.creator != selectedAcc) {
      setTaskDetail((prevState) => {
        return { ...prevState, creator: selectedAcc, projectId: null };
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(e) {
      if (
        modalRef.current &&
        !modalRef.current.contains(e.target) &&
        !e.target.classList.contains(styles["header-menu-acc"])
      ) {
        onClose();
      }
    }
    if (modalRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={modalRef} className={styles["AccModal-main"]}>
      {accountData.accountInfo.accounts.map((item) => (
        <div className={styles["AccModal-row"]}>
          <RadioBtn
            onClick={handleRadioBtn}
            checked={data.creator == item.email ? true : false}
            name="account"
            label={item.email}
            labelWidth="180px"
          />
          <div
            className={styles["AccModal-img"]}
            style={{
              backgroundImage: `url(${item.photoUrl})`,
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

const TaskDetail = ({ guestPopupRef, recurringPopupRef }) => {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const { data, loadData } = taskDetail;
  const [defaultVisibility, setDefaultVisibility] = useRecoilState(visibilityState);
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [isFirstCreated, setIsFirstCreated] = useRecoilState(isFirstCreatedState);
  const [saveEvent, setSaveEvent] = useRecoilState(saveEventState);

  const [inboxTaskList, updateInboxTaskList] = useRecoilState(inboxTaskListState);
  const [doneTaskList, updateDoneTaskList] = useRecoilState(doneTaskListState);
  const [toast, setToast] = useRecoilState(toastState);
  const [taskDetailModalHeight, setTaskDetailModalHeight] = useRecoilState(taskDetailHeightState);
  const meetWithAccounts = useRecoilValue(meetWithAccountsState);
  const setSelectedSpaces = useSetRecoilState(selectedSpaceIdListState);

  const [guestPopup, setGuestPopup] = useRecoilState(guestPopupState);
  const [recurringPopup, setRecurringPopup] = useRecoilState(recurringPopupState);

  const [selectedVisibilityType, setSelectedVisibilityType] = useState(
    data && data.visibility
      ? typeForVisibility(data.visibility, data.transparency)
      : defaultVisibility
  );
  const [toggleExpand, setToggleExpand] = useState(false);
  const [isAccModal, setIsAccModal] = useState(false);
  const [guestError, setGuestError] = useState(false);
  const [isSidebarModalOn, setIsSidebarModalOn] = useState(false);
  const [modalPosition, setModalPosition] = useState({
    top: taskDetail.modalPosition.y,
    left: taskDetail.modalPosition.x,
  });
  const [taskPopupInitialData, setTaskPopupInitialData] = useState(data);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const [isTask, setIsTask] = useState(
    taskDetail.data && (taskDetail.data.taskType === "Task" ?? null)
  );
  const [isVisibilityClick, setIsVisibilityClick] = useState(false);
  const [taskDetailDataChanged, setTaskDetailDataChanged] = useState(false);
  const [isModalNoteClicked, setIsModalNoteClicked] = useState(false);
  const calendarViewBaseDate = useRecoilValue(calendarViewBaseDateState);
  const [localTitle, setLocalTitle] = useState(data.title);

  const [duplicateItem, setDuplicateItem] = useRecoilState(calendarEventDuplicateItemState);

  const mainRef = useRef(null);
  const titleRef = useRef(null);

  const [initialBlockData, setInitialBlockData] = useState({});

  const taskDetailRef = useRef(null);

  const initialTaskType = useRef(null);
  const initialTransparency = useRef(null);
  const initialVisibility = useRef(null);
  const initialTitle = useRef(null);
  const initialProjectId = useRef("");
  const initialRecurrence = useRef(null);
  const initialGuest = useRef(null);
  const initialAllday = useRef(null);
  const initialStart = useRef(null);
  const initialEnd = useRef(null);
  const initialVideo = useRef(null);
  const initialLinks = useRef(null);
  const initialNoteData = useRef("");

  const currentIsCreateSelectEvent = useRef(null);
  const currentIsDataDuplicateEvent = useRef(null);
  const isChangeNote = useRef(false);

  const latestDataRef = useRef(data); // 가장 최신의 Data를 저장하는 변수
  const moreModalRef = useRef(null);
  const linkMoreModalRef = useRef(null);
  const videoCreateModalRef = useRef(null);
  const repeatModalRef = useRef(null);
  const visibilityDropdownRef = useRef(null);
  const guestDropdownRef = useRef(null);
  const repeatAddModalRef = useRef(null);

  const projectDropdownRef = useRef(null);

  const startDateRef = useRef(null);
  const startTimeRef = useRef(null);
  const endDateRef = useRef(null);
  const endTimeRef = useRef(null);

  const [isDateStart, setIsDateStart] = useState(false);
  const [isDateEnd, setIsDateEnd] = useState(false);
  const [isTimeEnd, setIsTimeEnd] = useState(false);

  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [isTimeStart, setIsTimeStart] = useState(false);

  const api = useApi();
  const trackCall = useJuneTrackCall();
  const trackNoteLinkChanges = useJuneTrackChanges();

  const isDisabled = currentIsCreateSelectEvent.current || currentIsDataDuplicateEvent.current;

  const { openRecurringPopup } = useOpenRecurringPopup();
  const { openGuestPopup } = useOpenGuestPopup();

  const { mutateMeetingCode, meetingCode, isPendingMeetingCode, error } = useFetchMeetingCode();
  const { mutate: deleteRecurrenceMutate } = useDeleteRecurrenceBlock();
  const { mutate: updateRecurrenceMutate } = useUpdateRecurrenceBlock();
  const { invalidateCalendarEvents } = useFetchCalendarEvents();

  // NOTE TaskDetail 모달 위치 기본 설정 로직
  const calculateModalStyle = useCallback(() => {
    // NOTE TaskDetail 모달 위치 기본 설정 로직

    const targetRect = taskDetail.targetRect;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const modalWidth = 362;
    let modalHeight = taskDetail.data ? taskDetail.data.modalHeight : 600;

    if (taskDetailRef.current) {
      modalHeight = taskDetailRef.current.offsetHeight + 2;
    }

    // targetRect의 속성들을 양수로 조정
    const adjustedRect = targetRect
      ? {
          x: targetRect.x < 0 ? targetRect.x : targetRect.x,
          y: targetRect.y < 0 ? targetRect.y : targetRect.y,
          top: targetRect.top < 0 ? targetRect.top : targetRect.top,
          bottom: targetRect.bottom < 0 ? targetRect.bottom : targetRect.bottom,
          right: targetRect.right,
          left: targetRect.left,
        }
      : null;

    let x = adjustedRect ? adjustedRect.right + 5 : taskDetail.modalPosition.x;
    let y = adjustedRect ? adjustedRect.top : taskDetail.modalPosition.y;

    // 모달이 화면의 오른쪽을 벗어나면 왼쪽에 맞춤
    if (x + modalWidth > windowWidth) {
      x = adjustedRect ? adjustedRect.left - modalWidth - 5 : Math.max(windowWidth - modalWidth, 0);
    }

    // 모달이 화면의 아래쪽을 벗어나면 아래쪽에 맞춤
    if (y + modalHeight > windowHeight) {
      y = windowHeight - modalHeight - 20;
    } else if (y < 110) {
      y = 110;
    }

    return { top: `${y}px`, left: `${x}px` };
  }, []);

  useEffect(() => {
    if (taskDetailRef.current) {
      setModalPosition(calculateModalStyle());
      setTaskDetailModalHeight(taskDetailRef.current.offsetHeight + 2);
    }
  }, [taskDetailRef.current, taskDetail.data]);

  useEffect(() => {
    if (taskDetailRef.current) {
      setTaskDetailModalHeight(taskDetailRef.current.offsetHeight);
    }
  }, [taskDetailRef.current]);

  useEffect(() => {
    latestDataRef.current = data;
  }, [data]);

  const handleClose = () => {
    const handleEventDeletion = () => {
      if (taskDetail.handleEventDelete) {
        taskDetail.handleEventDelete(taskDetail.data.id);
      }
    };

    if (currentIsCreateSelectEvent.current || currentIsDataDuplicateEvent.current) {
      handleEventDeletion();
    }

    setTaskDetail({ ...taskDetail, isVisible: false });
    setIsFirstCreated(false);

    // NOTE TaskDetail 닫을 때 초기화
    setTaskDetail((prev) => ({
      ...prev,
      data: null,
    }));
  };

  const updateMobaEventListForDeletion = (event, selectedOption) => {
    if (event.start && event.end) {
      taskDetail.handleEventDelete(
        event.id,
        event.start.date || event.start.dateTime,
        event.recurringEventId,
        selectedOption
      );
    }
  };

  const handleTaskDelete = async () => {
    if (
      (taskDetail.data.recurrence && taskDetail.data.recurrence.length > 0) ||
      (data.attendees && data.attendees.length > 0)
    ) {
      try {
        let selectedRecurringOption = "";
        let notifyGuests = false;

        // 1. RecurringPopup 열기 (반복 이벤트가 있는 경우)
        if ((data.recurrence && data.recurrence.length > 0) || data.recurringEventId) {
          selectedRecurringOption = await openRecurringPopup(taskDetail.data, "delete");
        }

        // 2. 게스트가 있을 경우 GuestPopup 열기
        if (data.attendees && data.attendees.length > 0) {
          notifyGuests = await openGuestPopup(data, "delete");
        }

        // 3. 반복 이벤트가 있을 경우 API로 삭제 요청
        if (selectedRecurringOption) {
          updateMobaEventListForDeletion(data, selectedRecurringOption);

          try {
            api
              .delete(`tasks/recurrence`, {
                headers: { "X-Requester": taskDetail.data.creator },
                params: {
                  eventId: taskDetail.data.id,
                  option: selectedRecurringOption,
                  notification: notifyGuests, // 게스트 알림 여부
                },
              })
              .then(() => {
                setToast({
                  type: "Delete",
                  isVisible: true,
                  message: "Event has been deleted",
                });
              });
          } catch (error) {
            console.log("error", error);
          }
        } else {
          updateMobaEventListForDeletion(data);

          api.patch(`tasks/${data.id}/mark`, "", {
            params: {
              notification: guestPopup.clickType === "ok",
            },
            headers: { "X-Requester": data.creator },
          });
        }

        handleClose();

        trackCall("delete_block", {
          location: taskDetail.type,
          type: taskDetail.data.attendees?.length > 0 ? "meeting" : "task",
        });

        setToast({
          type: "Delete",
          isVisible: true,
          message: "Event has been deleted",
        });
      } catch (error) {
        console.error("Event deletion failed", error);
      }
    } else {
      // TODO 반복 이벤트 아닌 경우에는 바로 삭제
      const handleEventDeletion = () => {
        if (taskDetail.handleEventDelete) {
          taskDetail.handleEventDelete(data.id);
        }
      };

      handleEventDeletion();

      updateInboxTaskList((current) => current.filter((task) => task.id !== data.id));

      handleClose();

      // NOTE TaskDetail 내 삭제 버튼 클릭 시 toast
      setToast({
        type: "Delete",
        isVisible: true,
        message: "Event has been deleted",
      });

      if (!currentIsCreateSelectEvent.current && !currentIsDataDuplicateEvent.current) {
        api
          .patch(`tasks/${data.id}/mark`, "", {
            headers: { "X-Requester": data.creator },
          })
          .then(() => {
            loadData(true, true, false);
            trackCall("delete_block", {
              location: taskDetail.type,
              type: taskDetail.data.attendees?.length > 0 ? "meeting" : "task",
            });
          })
          .catch(() => {
            loadData(true, true, true);
          });
      }
    }
  };

  useEffect(() => {
    if (guestPopup.clickType === "ok" || guestPopup.clickType === "cancel") {
      // handleSave(guestPopup.data);
    } else if (guestPopup.clickType === "discard") {
      if (guestPopup.isDataDuplicateWithGuest) {
        if (duplicateItem instanceof HTMLElement) {
          duplicateItem.remove();
        }
      }
      // handleClose();
    }
  }, [guestPopup]);

  // NOTE TaskDetail 처음 창 열었을 때 동작하는 로직
  useEffect(() => {
    // NOTE 초기값 ref에 저장, 변경사항 체크할 때 사용
    const nullDeletedInitialData = deleteNullValueInObject(data);
    const duplicatedProcessedInitialData = {
      ...nullDeletedInitialData,
      attendees: nullDeletedInitialData.isDataDuplicateEvent
        ? []
        : (nullDeletedInitialData.attendees ?? []),
    };

    setInitialBlockData(duplicatedProcessedInitialData);

    // TODO 추후 initialBlockData로 마이그레이션
    initialTaskType.current = data.taskType;
    initialTransparency.current = data.transparency;
    initialVisibility.current = data.visibility;
    initialTitle.current = data.title;
    initialProjectId.current = data.projectId;
    initialAllday.current = data.allDay;
    initialEnd.current = data.end;
    initialStart.current = data.start;
    initialGuest.current = data.isDataDuplicateEvent ? [] : (data.attendees ?? []);
    initialVideo.current = data.hangoutLink ? data.hangoutLink : null;
    initialLinks.current = data.links;
    initialRecurrence.current = data.recurrence;

    currentIsCreateSelectEvent.current = data.isCreateSelectEvent;
    currentIsDataDuplicateEvent.current = data.isDataDuplicateEvent;

    let trackObject = { location: taskDetail.type };
    if (data.attendees != null && data.attendees.length) {
      trackObject = { ...trackObject, type: "meeting" };
    } else {
      trackObject = { ...trackObject, type: "task" };
    }
    trackCall("view_block_detail", trackObject);

    api
      .get(`notes/${data.recurringNoteId ? data.recurringNoteId : data.id}/${data.creator}`)
      .then((res) => {
        initialNoteData.current = data.note ? data.note : res.data.note || "";
        setTaskDetail((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            note: data.note ? data.note : res.data.note || "",
          },
        }));
      });
  }, []);

  // NOTE TaskDetail 바깥 클릭 시 저장 또는 닫기
  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();

      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        // linkModal의 more context menu 클릭 시 창 닫히지 않도록 설정
        (!linkMoreModalRef.current || !linkMoreModalRef.current.contains(event.target)) &&
        !videoCreateModalRef.current &&
        // repeatModal 닫히지 않도록 설정
        !repeatModalRef.current &&
        !projectDropdownRef.current &&
        !recurringPopupRef.current &&
        !guestDropdownRef.current
      ) {
        return onSave(localTitle);
      }
    }
    if (mainRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      // if (currentIsCreateSelectEvent.current) {
      //   if (taskDetail.handleEventDelete) {
      //     taskDetail.handleEventDelete(data.id);
      //   }
      // }
      // NOTE 여기서 초기화 시 checkChanges 함수 동작 안하는 이슈 발생으로 주석 처리
      // setTaskDetail((prev) => ({
      //   ...prev,
      //   data: {
      //     ...prev.data,
      //     createdAt: null,
      //     creator: null,
      //     id: null,
      //     itemStatus: null,
      //     title: null,
      //     projectId: null,
      //     links: null,
      //     files: null,
      //     note: null,
      //     start: null,
      //     end: null,
      //     repeat: null,
      //     attendees: null,
      //     note: null,
      //     // taskType: null,
      //   },
      // }));
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [taskDetail.data, isTask, isPendingMeetingCode, localTitle]);

  const checkChanges = useCallback(
    (data, isCurrentTask, localTitle) => {
      const taskTypeChanged = isCurrentTask !== (initialTaskType.current === "Task");

      const visibilityChanged = initialVisibility.current !== data.visibility;
      const transparencyChanged = initialTransparency.current !== data.transparency;
      const totalVisibilityChanged = visibilityChanged || transparencyChanged;

      const titleChanged = initialTitle.current !== localTitle;

      const projectIdChanged = initialProjectId.current !== data.projectId;

      let startChanged = false;
      let endChanged = false;
      if (!initialStart.current && !data.start) {
        startChanged = false;
        endChanged = false;
      } else {
        if (!data.start) {
          startChanged = true;
        } else {
          if (initialAllday.current !== data.allDay) {
            startChanged = true;
          } else {
            if (data.allDay) {
              startChanged =
                new Date(initialStart.current).getTime() !== new Date(data.start.date).getTime();
            } else {
              startChanged =
                new Date(initialStart.current).getTime() !==
                new Date(data.start.dateTime).getTime();
            }
          }
        }
        if (!initialEnd.current && !data.end) {
          startChanged = false;
          endChanged = false;
        } else {
          if (!data.end) {
            endChanged = true;
          } else {
            if (initialAllday.current !== data.allDay) {
              endChanged = true;
            } else {
              if (!data.allDay) {
                endChanged =
                  new Date(initialEnd.current).getTime() !== new Date(data.end.dateTime).getTime();
              }
            }
          }
        }
      }

      let linksChanged = false;
      if (initialLinks.current) {
        linksChanged =
          initialLinks.current.length !== data.links.length ||
          initialLinks.current.some((link, idx) => link.url !== data.links[idx].url);
      } else if (data.links && data.links.length > 0) {
        linksChanged = true;
      }
      const repeatChanged =
        JSON.stringify(initialRecurrence.current) !== JSON.stringify(data.recurrence);
      const guestChanged = isAttendeesChanged(initialGuest.current, data.attendees);
      const videoChanged = initialVideo.current !== data.hangoutLink;

      if (isPendingMeetingCode) {
        return false;
      } else {
        return (
          (!data.isCreateSelectEvent && taskTypeChanged) ||
          totalVisibilityChanged ||
          titleChanged ||
          projectIdChanged ||
          startChanged ||
          endChanged ||
          repeatChanged ||
          guestChanged ||
          videoChanged ||
          linksChanged
        );
      }
    },
    [isTask, isPendingMeetingCode, taskDetail.data, localTitle]
  );

  let hasChanged;
  useEffect(() => {
    if (taskDetail.data) {
      hasChanged = checkChanges(taskDetail.data, isTask, localTitle);
      // NOTE save 버튼 활성화용 상태
      setTaskDetailDataChanged(hasChanged || isNoteDataChanged(initialNoteData.current, data.note));
    }
  }, [taskDetail.data, isTask, checkChanges, isPendingMeetingCode, localTitle]);

  function onSave(localTitle) {
    const prepareUpdateTask = () => {
      const {
        id,
        creator,
        title,
        start,
        end,
        itemStatus,
        links,
        projectId,
        recurrence,
        attendees,
        note,
        visibility,
        transparency,
        hangoutLink,
        meetingCode,
        taskType,
        startTimeZone,
        recurringEventId,
        allDay,
      } = data;
      const updateTask = {
        id,
        creator,
        title: localTitle || title || "No title",
        start,
        end,
        itemStatus,
        links,
        projectId,
        recurrence,
        attendees: attendees || [],
        note,
        visibility,
        transparency,
        taskType: isTask ? "Task" : "Event",
        recurringEventId,
        allDay,
      };

      if (meetingCode) {
        updateTask.meetingCode = meetingCode || "";
      }

      if (hangoutLink) {
        updateTask.hangoutLink = hangoutLink;
      }

      // Null 값 제거
      const nullDeletedUpdateTask = deleteNullValueInObject(updateTask);

      return nullDeletedUpdateTask;
    };

    const checkAndSave = async (updateTask, isCurrentOptionEnabled = true) => {
      // guest 있는데 날짜 없을 경우 체크

      // 캘린더 요소일 경우에만
      if (updateTask.start && updateTask.end) {
        const initialStartDate = new Date(initialStart.current);
        const initialEndDate = new Date(initialEnd.current);
        const dataStartDate = new Date(
          latestDataRef.current.start.dateTime || latestDataRef.current.start.date
        );
        const dataEndDate = new Date(
          latestDataRef.current.end.dateTime || latestDataRef.current.end.date
        );

        const isStartEqual = areDateTimeEqual(initialStartDate, dataStartDate);
        const isEndEqual = areDateTimeEqual(initialEndDate, dataEndDate);

        // 만약 guest 가 바뀌었고(없었는데 생기거나 있었는데 게스트 변경 시), 반복일정이나,제목, 날짜가 변경되었을 때 팝업 띄우기
        if (
          meetWithAccounts.length > 0 ||
          initialRecurrence.current !== latestDataRef.current.recurrence ||
          isAttendeesChanged(initialGuest.current, latestDataRef.current.attendees) ||
          initialTitle.current !== latestDataRef.current.title ||
          !isStartEqual ||
          !isEndEqual
        ) {
          handleRecurringAndGuest(updateTask, isCurrentOptionEnabled);

          setTaskDetail((prev) => ({
            ...prev,
            isVisible: false,
          }));
        } else {
          handleSave(updateTask);
        }
        setIsFirstCreated(false);
      } else {
        setToast({ isVisible: true, message: "Please block the date." });
        setGuestError(true);
      }
    };

    const handleRecurringAndGuest = async (updateTask, isCurrentOptionEnabled) => {
      try {
        let finalSelectedOption;

        // 1. 처음 생성된 이벤트, 복제된 이벤트가 아닌 경우에만 RecurringPopup 호출
        // 팝업을 띄우는 조건은 반복이 이전에도 있었고, 지금도 있는 경우에만 띄움
        if (
          initialRecurrence.current &&
          initialRecurrence.current.length > 0 &&
          updateTask.recurrence &&
          updateTask.recurrence.length > 0
        ) {
          const recurringType = "edit";
          const isDateEqual =
            areDateEqual(
              new Date(updateTask.start.dateTime || updateTask.start.date),
              new Date(initialStart.current)
            ) &&
            areDateEqual(
              new Date(updateTask.end.dateTime || updateTask.end.date),
              new Date(initialEnd.current)
            );

          finalSelectedOption = await openRecurringPopup(
            updateTask,
            recurringType,
            isDateEqual,
            isCurrentOptionEnabled
          );
        }

        // 2. 게스트가 있을 경우 GuestPopup 호출 (복제된 이벤트도 게스트가 있으면 팝업 띄움)
        // 단, 게스트가 있을 때에도 title, 시작/종료 시간(allday는 이안에 포함됨), 반복, guest, video 수정 시에만 guest popup 띄움
        let notifyGuests = false;

        if (updateTask.attendees.length > 0 && shouldNotifyGuests(initialBlockData, updateTask)) {
          notifyGuests = await openGuestPopup(
            updateTask,
            currentIsCreateSelectEvent.current ? "create" : "edit"
          );
        }

        // 3. 처음 생성(클릭앤드래그), 복제된 이벤트인 경우 바로 저장로직(handleSave) 호출 (saveTaskChanges는 호출되지 않음)
        if (
          currentIsDataDuplicateEvent.current ||
          currentIsCreateSelectEvent.current ||
          // 반복이 없었다가 생긴 경우에는 /task로 저장
          ((!initialRecurrence.current || initialRecurrence.current.length === 0) &&
            updateTask.recurrence?.length > 0) ||
          // 반복 없이 게스트만 있는 경우에는 /task로 저장
          (updateTask.recurrence?.length === 0 && updateTask.attendees?.length > 0)
        ) {
          handleSave(updateTask, notifyGuests); // 복제된 이벤트는 원래의 저장 로직 실행
        } else {
          // 복제된 이벤트가 아닌 경우에만 Recurrence API 호출
          await saveTaskChanges(updateTask, finalSelectedOption, notifyGuests);
        }

        setToast({
          type: "Success",
          isVisible: true,
          message: "Event updated successfully",
        });
      } catch (error) {
        console.error("Event update failed", error);
      }
    };

    // RecurringPopup을 Promise로 호출하는 함수

    const handleUpdateRecurringData = async (updateTask) => {
      try {
        const recurringType = "edit";

        const isDateEqual =
          areDateEqual(
            new Date(updateTask.start.dateTime || updateTask.start.date),
            new Date(initialStart.current)
          ) &&
          areDateEqual(
            new Date(updateTask.end.dateTime || updateTask.end.date),
            new Date(initialEnd.current)
          );

        const finalSelectedOption = await openRecurringPopup(
          updateTask,
          recurringType,
          isDateEqual
        );
        await saveTaskChanges(updateTask, finalSelectedOption, false);
        setToast({
          type: "Success",
          isVisible: true,
          message: "Event updated successfully",
        });
      } catch (error) {
        console.error("Event update failed", error);
      }
    };

    // API 호출 함수
    const saveTaskChanges = (updateTask, selectedOption, notifyGuests) => {
      if (updateTask.start && updateTask.end) {
        taskDetail.handleEventChange(
          updateTask,
          initialStart.current,
          initialEnd.current,
          selectedOption,
          undefined,
          initialRecurrence.current
        );
      }

      updateRecurrenceMutate(
        {
          updatedBlockData: updateTask,
          selectedOption: selectedOption,
          notify: notifyGuests,
          creator: updateTask.creator,
        },
        {
          onSuccess: () => invalidateCalendarEvents(),
        }
      );

      return;
    };

    // 이 updateTask가 최종적으로 바뀐 데이터, prepareUpdateTask로 null 값 제거
    const updateTask = prepareUpdateTask();

    if (
      checkChanges(taskDetail.data, isTask, localTitle) ||
      isNoteDataChanged(initialNoteData.current, data.note)
    ) {
      if (!taskPopupInitialData.start && updateTask.start && updateTask.end) {
        updateInboxTaskList((current) => current.filter((task) => task.id !== updateTask.id));
      }

      if (updateTask.attendees && updateTask.attendees.length > 0) {
        const isDateTimeExist = updateTask.start && updateTask.end;

        // 1-1 guest 있는데 날짜 없는 경우 예외 처리
        if (!isDateTimeExist) {
          setToast({ isVisible: true, message: "Please block the date." });
          setGuestError(true);
          return;
        }

        // 1-2 guest 있는데 날짜 있는 경우 (정상)
        // 1-2-1 반복이 없었다가 생성 되었을 때 / 반복이 계속 없는 경우 => 결과적으로 최종 값에 반복이 없는 경우
        // 1-2-2 시작값에 반복 값이 있는 경우 1. 반복 값 그대로(정상), 2. 반복 값 수정(정상), 3. 반복 값 삭제(이경우 로직 없음)
        // => 모두 같은 로직으로 처리 가능

        handleRecurringAndGuest(updateTask, true);

        setTaskDetail((prev) => ({
          ...prev,
          isVisible: false,
        }));
        return;
      } else {
        // 2. guest 없을 때
        if (
          (updateTask.recurrence && updateTask.recurrence.length > 0) ||
          updateTask.recurringEventId
        ) {
          // 2-1 게스트 없고, 바뀐값 반복 있을 때
          if (!initialRecurrence.current || initialRecurrence.current.length === 0) {
            // 2-1-1 게스트 없고 바뀐값 반복 있을 때, 원래는 반복 없다가 생긴 경우
            handleSave(updateTask);
          } else {
            // 2-1-2 게스트 없고 최종 값에 반복 있을 때, 반복 값이 원래 있었을 때
            if (
              initialRecurrence.current &&
              updateTask.recurrence &&
              initialRecurrence.current[0] === updateTask.recurrence[0]
            ) {
              // 2-1-2-1 게스트 없고 최종 값에 반복 있는데 [원래 있었고 같은 값인 경우]
              handleUpdateRecurringData(updateTask);
              setTaskDetail((prev) => ({
                ...prev,
                isVisible: false,
              }));
              setIsFirstCreated(false);
            } else {
              // 2-1-2-2 게스트 없고 반복값은 [원래 있었는데 있었는데 수정되었을 때]

              // NOTE 반복 값이 없어진 경우 중 dateTime 자체를 삭제해서 반복값이 사라지는 경우(반복 task calendar -> inbox 이동)
              // 이 경우 start,end는 있다가 없어지고, 반복값도 있다가 없어지는 경우

              // (dateTime과 반복이 같이 사라진 경우 -> 캘린더에서 인박스로 이동한 경우) 가 아닐 때 아래 if 문
              // 나머지는 (dateTime과 반복이 같이 사라진 경우 -> 캘린더에서 인박스로 이동한 경우)
              if (
                !initialStart.current ||
                updateTask.start ||
                (updateTask.recurrence && updateTask.recurrence.length !== 0)
              ) {
                checkAndSave(updateTask, false);

                return;
              }

              // NOTE 해당 task와 관련된 모든 반복 이벤트 삭제 + 인박스 task 생성
              const {
                creator,
                projectId,
                recurringEventId: id, // 기존의 recurringEventId를 newItem의 id로 사용
                taskType,
                title,
                transparency,
                visibility,
                links,
                note,
              } = updateTask;

              const newItem = {
                creator,
                projectId,
                id,
                itemStatus: "InProgress", // 새로운 상태값 할당
                taskType,
                title: localTitle || title,
                transparency,
                visibility,
                links,
                note,
              };

              // NOTE 클라이언트 상태 업데이트
              taskDetail.handleEventDelete(
                updateTask.id,
                undefined,
                updateTask.recurringEventId,
                "all"
              );
              updateInboxTaskList((current) => [newItem, ...current]);

              // NOTE 서버 업데이트
              deleteRecurrenceMutate({
                eventId: updateTask.id, // 클릭한 요소의 id
                option: "all",
                creator: updateTask.creator,
              });
              api.post("tasks", newItem, {
                headers: {
                  "X-Requester": accountData.accountInfo.accounts[0].email,
                },
              });

              handleClose();
            }
          }
        } else {
          // 2-2 게스트 없고, 바뀐값 반복 없을 때
          handleSave(updateTask);
        }
      }
    } else {
      // 변경사항 없을 때
      handleClose();
    }

    handleChangeLocalTitle("");
  }

  const handleSave = async (updateTask, notifyGuest) => {
    // null 값 제거한 updateTask 받아서
    if (updateTask.start && updateTask.end) {
      if (currentIsCreateSelectEvent.current) {
        setToast({
          type: "Success",
          isVisible: true,
          message: "Event created successfully",
        });
      } else {
        setToast({
          type: "Success",
          isVisible: true,
          message: "Event updated successfully",
        });
      }
    }

    setIsFirstCreated(false);

    // June 설정 로직
    const trackTask = (taskType, additionalData = {}) => {
      let trackObject = { location: taskDetail.type, type: taskType };

      if (updateTask.attendees.length > 0) {
        trackObject = { ...trackObject, type: "meeting" };
      } else {
        trackObject = { ...trackObject, type: "task" };
      }

      trackObject = {
        ...trackObject,
        type: updateTask.taskType === "Task" ? "task" : "event",
        knowledge: !!updateTask.links?.length,
        note: isChangeNote.current,
        space: !!updateTask.projectId?.length,
        visibility: updateTask.visibility
          ? typeForVisibility(updateTask.visibility, updateTask.transparency)
          : undefined,
        repeat: !!updateTask.recurrence?.length,
        title: initialTitle.current !== localTitle,
        ...additionalData,
      };
      trackCall(taskType, trackObject);
    };

    // June 설정 로직 - 시간 변경 시
    const trackTimeChange = (beforeStart, beforeEnd, afterStart, afterEnd) => {
      if (beforeStart !== afterStart || beforeEnd !== afterEnd) {
        if (initialStart.current === undefined) {
          trackCall("block_time", {
            location: "inbox",
            start_datetime: afterStart,
            end_datetime: afterEnd,
          });
        } else {
          const juneTimeChageLog = {
            previous_start_datetime: beforeStart,
            previous_end_datetime: beforeEnd,
            new_start_datetime: afterStart,
            new_end_datetime: afterEnd,
          };

          if (beforeStart === afterStart || beforeEnd === afterEnd) {
            trackCall("resize_block", juneTimeChageLog);
          } else {
            trackCall("move_block", {
              ...juneTimeChageLog,
              allDay: false,
            });
          }
        }
      }
    };

    const finalizeTasks = (res) => {
      // NOTE 서버에서 전달받은 데이터로 클라이언트 상태 다시 업데이트
      let calendarUpdateData = {
        ...latestDataRef.current,
        title: res.data.task.title,
        hangoutLink: res.data.task.hangoutLink ? res.data.task.hangoutLink : null,
        meetingCode: res.data.task.attendees ? res.data.task.hangoutLink : null,
        taskType: isTask ? "Task" : "Event",
        isCreateSelectEvent: false,
        isDataDuplicateEvent: false,
      };

      if (taskDetail.handleEventChange) {
        if (updateTask.start) {
          taskDetail.handleEventChange(
            calendarUpdateData,
            undefined,
            undefined,
            undefined,
            latestDataRef.current.isDataDuplicateEvent
          );
        }
      } else {
        if (updateTask.start || updateTask.end) {
          setSaveEvent(calendarUpdateData);
        }
      }

      setGuestPopup({
        type: "",
        isVisible: false,
        headerTitle: "",
        message: "",
        okButtonTitle: "",
        closeButtonTitle: "",
        discardButtonTitle: "",
        data: null,
      });
    };

    // 클라이언트 상태 업데이트 하는 부분
    const updateTaskData = () => {
      // 1. event delete, eventChange 가 모두 있을 때
      if (taskDetail.handleEventDelete && taskDetail.handleEventChange) {
        if (!updateTask.start && !updateTask.end) {
          // 1-1 인박스 요소면 해당 요소 삭제, 만약 Completed 요소면 doneTaskList 전역상태 업데이트, 아니면 inboxTaskList 전역상태 업데이트
          taskDetail.handleEventDelete(latestDataRef.current.id);

          const newItem = {
            ...updateTask,
            title: localTitle,
          };
          latestDataRef.current.itemStatus === COMPLETED
            ? updateDoneTaskList((current) => [newItem, ...current])
            : updateInboxTaskList((current) => [newItem, ...current]);
        } else {
          // 1-2 캘린더 요소면 해당 요소 업데이트

          // Repeat이 처음 생긴 요소거나, 클릭 앤 드래그로 생성된 요소 일때 handleEventChange 내에서 반복 이벤트 처리
          const isRepeatFirstCreated =
            (!initialRecurrence.current || initialRecurrence.current.length === 0) &&
            updateTask.recurrence &&
            updateTask.recurrence.length > 0;

          taskDetail.handleEventChange(
            updateTask,
            undefined,
            undefined,
            undefined,
            latestDataRef.current.isCreateSelectEvent || isRepeatFirstCreated
          );
        }
      } else {
        // 2. event delete, eventChange 가 모두 있지 않을 때(하나만 있거나 둘 다 없을 때)
        if (!updateTask.start && !updateTask.end) {
          // 2-1-1 인박스 요소고 해당 요소의 상태가 InProgress일 때 inboxTaskList 전역상태 업데이트
          // NOTE Inbox 상태 업데이트
          if (latestDataRef.current.itemStatus === INPROGRESS) {
            updateInboxTaskList((current) =>
              current.map((task) =>
                task.id === latestDataRef.current.id
                  ? { ...task, ...latestDataRef.current, title: localTitle }
                  : task
              )
            );
          } else {
            // 2-1-2 인박스 요소고 해당 요소의 상태가 Done일 때 doneTaskList 전역상태 업데이트
            updateDoneTaskList((current) =>
              current.map((task) =>
                task.id === latestDataRef.current.id
                  ? { ...task, ...latestDataRef.current, title: localTitle }
                  : task
              )
            );
          }
        } else {
          // 2-2 캘린더 요소일 때 saveEvent 전역 상태 업데이트
          setSaveEvent(latestDataRef.current);
        }
      }
    };

    currentIsCreateSelectEvent.current = false;
    currentIsDataDuplicateEvent.current = false;

    const handleApiCall = (url, method, task) => {
      api[method](url, task, {
        headers: { "X-Requester": latestDataRef.current.creator },
      })
        .then((res) => {
          finalizeTasks(res);
        })
        .catch((error) => {
          console.error(error);
          loadData(true, true, true);
        });
    };

    // NOTE 서버로 전송하는 필드
    const prepareUpdateTask = () => {
      let task = {
        ...data,
        title: localTitle ? localTitle : "No title",
        taskType: isTask ? "Task" : "Event",
      };

      if (task.start && task.end) {
        if (!task.start.dateTime && !task.end.date) {
          task.start = { dateTime: task.start, timeZone: task.startTimeZone };
          task.end = { dateTime: task.end, timeZone: task.endTimeZone };
        }
      }

      if (data.meetingCode) {
        // 값이 없으면 필드 제외
        task.meetingCode = data.meetingCode;
      }

      Object.keys(task).forEach((key) => {
        if (task[key] === null) delete task[key];
      });

      if (task.start) {
        task.id = latestDataRef.current.id;
      }
      return task;
    };

    if (latestDataRef.current.isCreateSelectEvent) {
      if (updateTask.start && updateTask.end) {
        if (updateTask.start.dateTime && updateTask.end.dateTime) {
          // NOTE all-day 아닐 떄
          trackTask("create_block", {
            location: "calendar",
            start_datetime: formatDateTimeForJune(updateTask.start.dateTime),
            end_datetime: formatDateTimeForJune(updateTask.end.dateTime),
          });
          setSelectedSpaces((prev) => addSelectedSpace(prev, updateTask.projectId));

          trackCall("block_time", {
            location: "calendar",
            start_datetime: formatDateTimeForJune(updateTask.start.dateTime),
            end_datetime: formatDateTimeForJune(updateTask.end.dateTime),
            allDay: false,
          });
        } else {
          // NOTE all-day 일 때
          trackTask("create_block", {
            location: "calendar",
            start_datetime: formatDateTimeForJune(
              new Date(updateTask.start.date).setHours(0, 0, 0, 0)
            ),
            end_datetime: formatDateTimeForJune(new Date(updateTask.end.date).setHours(0, 0, 0, 0)),
          });
          setSelectedSpaces((prev) => addSelectedSpace(prev, updateTask.projectId));

          trackCall("block_time", {
            location: "calendar",
            start_datetime: formatDateTimeForJune(
              new Date(updateTask.start.date).setHours(0, 0, 0, 0)
            ),
            end_datetime: formatDateTimeForJune(new Date(updateTask.end.date).setHours(0, 0, 0, 0)),
            allDay: true,
          });
        }
      }
    } else if (latestDataRef.current.isDataDuplicateEvent) {
      trackTask("duplicate_block");
    } else {
      trackTask("update_block");
      setSelectedSpaces((prev) => addSelectedSpace(prev, updateTask.projectId));

      const beforeStart = formatDateTimeForJune(initialStart.current);
      const beforeEnd = formatDateTimeForJune(initialEnd.current);
      const afterStart = formatDateTimeForJune(updateTask.start?.dateTime || "");
      const afterEnd = formatDateTimeForJune(updateTask.end?.dateTime || "");

      trackTimeChange(beforeStart, beforeEnd, afterStart, afterEnd);
    }

    // NOTE note, link 변경사항 체크 후 june 로그
    trackNoteLinkChanges(initialNoteData, data, initialLinks, taskDetail, isTask, toggleExpand);

    // 여기서부터 handleSave 실제 동작 부분
    // NOTE 클라이언트 상태 업데이트
    updateTaskData();

    // NOTE 서버 상태 업데이트
    // prepareUpdateTask로 null 값 제거한 updateTask 받아서
    const task = prepareUpdateTask();
    // TODO false일 경우에도 notifyGuest가 들어가야 하는지 확인 후 수정
    const notifyGuestParam =
      notifyGuest !== undefined && notifyGuest !== null ? `notification=${notifyGuest}` : "";

    // 클릭앤 드래그로 생성했거나 복제됐을 때에는 post로, 그 외에는 patch로
    const url =
      latestDataRef.current.isCreateSelectEvent || latestDataRef.current.isDataDuplicateEvent
        ? `tasks?${notifyGuestParam}`
        : `tasks/${latestDataRef.current.id}?creator=${latestDataRef.current.creator}&${notifyGuestParam}`;
    const method =
      latestDataRef.current.isCreateSelectEvent || latestDataRef.current.isDataDuplicateEvent
        ? "post"
        : "patch";

    handleApiCall(url, method, task);
    handleClose();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const blockNoteEl = document.querySelector(".bn-suggestion-menu");
      if (blockNoteEl) return;

      const bnDragHandleMenu = document.querySelector(".bn-drag-handle-menu");
      if (bnDragHandleMenu && event.key === "Escape") {
        return;
      }

      const bnToolbar = document.querySelector(".bn-toolbar");
      if (bnToolbar && event.key === "Escape") {
        return;
      }

      const isCmdOrCtrl = event.metaKey || event.ctrlKey;

      if (handlePopupKeyEvent(guestPopupRef, setGuestPopup, guestPopup, event)) return;
      if (handlePopupKeyEvent(recurringPopupRef, setRecurringPopup, recurringPopup, event)) return;

      if (moreModalRef.current && event.key === "Escape") {
        event.stopPropagation();
        if (document.activeElement) {
          document.activeElement.blur();
        }
        return setIsSidebarModalOn(false);
      }
      if (startTimeRef.current && event.key === "Escape") {
        event.stopPropagation();
        return setIsTimeStart(false);
      }
      if (startDateRef.current && event.key === "Escape") {
        event.stopPropagation();
        return setIsDateStart(false);
      }
      if (endTimeRef.current && event.key === "Escape") {
        return setIsTimeEnd(false);
      }
      if (endDateRef.current && event.key === "Escape") {
        return setIsDateEnd(false);
      }
      if (visibilityDropdownRef.current && event.key === "Escape") {
        if (document.activeElement) {
          document.activeElement.blur();
        }
        return setIsVisibilityClick(false);
      }
      if (repeatAddModalRef.current && event.key === "Enter") {
        event.stopPropagation();
        return;
      }
      if (repeatAddModalRef.current && event.key === "Escape") {
        event.stopPropagation();
        return;
      }
      if (projectDropdownRef.current && event.key === "Enter") {
        event.stopPropagation();
      }

      if (
        (!startTimeRef.current &&
          !videoCreateModalRef.current &&
          !projectDropdownRef.current &&
          !moreModalRef.current &&
          (!repeatModalRef.current || !repeatAddModalRef.current) &&
          !guestDropdownRef.current &&
          !guestPopupRef.current &&
          !recurringPopupRef.current &&
          event.key === "Escape") ||
        event.key === "Enter" ||
        (event.key === isCmdOrCtrl && event.key === "Enter")
      ) {
        event.stopPropagation();

        return onSave(localTitle);
      }

      if (
        event.key === "Backspace" ||
        event.key === "Delete" ||
        (event.key === "Backspace" && event.code === "Delete")
      ) {
        !toggleExpand && event.target.tagName !== "INPUT" && handleTaskDelete();
        !toggleExpand && event.target.tagName !== "INPUT" && handleTaskDelete();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    startTimeRef,
    setIsTitleFocused,
    data,
    repeatAddModalRef,
    toggleExpand,
    isPendingMeetingCode,
  ]);

  const handleVisibilityClick = () => {
    setIsVisibilityClick((current) => !current);
  };

  // NOTE 처음 taskDetail 창을 열었을 때에만 defaultVisibility 값으로 보이도록 설정
  useEffect(() => {
    isFirstCreated &&
      setTaskDetail((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          visibility: defaultVisibility === "public" ? "public" : "private",
          transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
        },
      }));
  }, [defaultVisibility]);

  useEffect(() => {
    isFirstCreated &&
      setSelectedVisibilityType(
        typeForVisibility(taskDetail.data.visibility, taskDetail.data.transparency)
      );
  }, [
    taskDetail.data && taskDetail.data.visibility,
    taskDetail.data && taskDetail.data.transparency,
  ]);

  const handleVisibilityDropdownItemClick = (type) => {
    setIsVisibilityClick(false);
    setSelectedVisibilityType(type);

    const updatedVisibility = type === "public" ? "public" : "private";
    const updatedTransparency = type === "invisible" ? "transparent" : "opaque";

    // 상태를 직접 업데이트
    setTaskDetail((prevDetail) => {
      const updatedDetail = {
        ...prevDetail,
        data: {
          ...prevDetail.data,
          visibility: updatedVisibility,
          transparency: updatedTransparency,
        },
      };
      return updatedDetail;
    });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (visibilityDropdownRef.current && !visibilityDropdownRef.current.contains(event.target)) {
        setIsVisibilityClick(false);
      }

      if (moreModalRef.current && !moreModalRef.current.contains(event.target)) {
        setIsSidebarModalOn(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMoreButtonClick = (event) => {
    setIsSidebarModalOn((prev) => !prev);
  };

  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setIsScrolling(true);
    const timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 2000);

    setScrollTimeout(timeout);
  };

  useEffect(() => {
    const element = document.querySelector(`.${styles.bodyMini}`);
    const bodyElement = document.querySelector(`.${styles.body}`);
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }

    if (bodyElement) {
      bodyElement.addEventListener("scroll", handleScroll);
      return () => {
        bodyElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if (isTask) {
      // event에서 task로 바뀌었다면 attendees 초기화 및 video 초기화
      setTaskDetail((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          attendees: [], // attendees 초기화
          hangoutLink: null, // hangoutLink 초기화
          taskType: "Task",
          meetingCode: "",
        },
      }));
    } else {
      setTaskDetail((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          taskType: "Event",
        },
      }));
    }
    if (data.isCreateSelectEvent) {
      setIsTitleFocused(true);
    }
  }, [isTask]);

  const handleChangeLocalTitle = (newLocalTitle) => {
    // 현재 hasChanged 값에 localTitle이 필요해서 여기에 상태 생성
    setLocalTitle(newLocalTitle);
  };

  return (
    <>
      <div
        className={clsx(styles.detail__wrap, {
          [styles["detail__wrap-mini"]]: !toggleExpand,
          [styles["detail__wrap-expand"]]: toggleExpand,
        })}
        style={!toggleExpand ? modalPosition : {}}
        ref={taskDetailRef}
      >
        <div
          className={clsx(styles.main, {
            [styles["main-mini"]]: !toggleExpand,
            [styles["main-expand"]]: toggleExpand,
          })}
          ref={mainRef}
        >
          <Header
            // Refs
            latestDataRef={latestDataRef}
            moreModalRef={moreModalRef}
            visibilityDropdownRef={visibilityDropdownRef}
            // States
            isTask={isTask}
            setIsTask={setIsTask}
            toggleExpand={toggleExpand}
            setToggleExpand={setToggleExpand}
            isDisabled={isDisabled}
            isVisibilityClick={isVisibilityClick}
            isSidebarModalOn={isSidebarModalOn}
            selectedVisibilityType={selectedVisibilityType}
            isModalNoteClicked={isModalNoteClicked}
            setIsModalNoteClicked={setIsModalNoteClicked}
            // Functions
            handleVisibilityClick={handleVisibilityClick}
            handleVisibilityDropdownItemClick={handleVisibilityDropdownItemClick}
            handleTaskDelete={handleTaskDelete}
            handleMoreButtonClick={handleMoreButtonClick}
          />
          <div
            className={`${toggleExpand ? styles.body : styles.bodyMini} ${isScrolling && styles.showScrollbar}`}
          >
            <TaskSetting
              titleRef={titleRef}
              localTitle={localTitle}
              handleChangeLocalTitle={handleChangeLocalTitle}
              expand={toggleExpand}
              loadData={loadData}
              onSave={onSave}
              onClose={handleClose}
              guestError={guestError}
              setGuestError={setGuestError}
              setVisibilityType={handleVisibilityDropdownItemClick}
              isChangeNote={isChangeNote}
              isDisabled={isDisabled}
              linkMoreModalRef={linkMoreModalRef}
              videoCreateModalRef={videoCreateModalRef}
              isTask={isTask}
              setIsTask={setIsTask}
              repeatModalRef={repeatModalRef}
              startTimeRef={startTimeRef}
              endTimeRef={endTimeRef}
              startDateRef={startDateRef}
              endDateRef={endDateRef}
              isTimeStart={isTimeStart}
              setIsTimeStart={setIsTimeStart}
              isTimeEnd={isTimeEnd}
              setIsTimeEnd={setIsTimeEnd}
              isDateStart={isDateStart}
              setIsDateStart={setIsDateStart}
              isDateEnd={isDateEnd}
              setIsDateEnd={setIsDateEnd}
              isTitleFocused={isTitleFocused}
              setIsTitleFocused={setIsTitleFocused}
              projectDropdownRef={projectDropdownRef}
              guestDropdownRef={guestDropdownRef}
              repeatAddModalRef={repeatAddModalRef}
              mutateMeetingCode={mutateMeetingCode}
              isPendingMeetingCode={isPendingMeetingCode}
              meetingCode={meetingCode}
              setIsModalNoteClicked={setIsModalNoteClicked}
              isModalNoteClicked={isModalNoteClicked}
            />
          </div>
          {!isModalNoteClicked && (
            <div className={styles.footer}>
              <button
                className={clsx(styles["setting-save--disabled"], {
                  [styles["setting-save"]]: taskDetailDataChanged,
                })}
                onClick={() => onSave(localTitle)}
              >
                <p>Save</p>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* {popupType && <RadioPopup {...popupProps} />} */}
    </>
  );
};

export default TaskDetail;
