import React from "react";
import { SideBarGroupSection } from "./SideBarGroupSection";
import { SideBarTitleSection } from "./SideBarTitleSection";
import { SideBarMenuButton } from "./SideBarMenuButton";
import { DateIcon, ProjectIcon } from "../../../icons";
import { viewModeState, ViewModeType } from "../../../recoil/view/viewMode";
import { useRecoilState } from "recoil";

interface SideBarViewSectionProps {
  isExpanded: boolean;
  onToggleSidebar: () => void;
}
export const SideBarViewSection: React.FC<SideBarViewSectionProps> = ({
  isExpanded,
  onToggleSidebar,
}) => {
  const [viewMode, setViewMode] = useRecoilState<ViewModeType>(viewModeState);

  const handleClickCalendar = () => {
    setViewMode("calendar");
  };

  const handleClickWiki = () => {
    setViewMode("wiki");
  };

  return (
    <SideBarGroupSection flex="0 0 auto" isExpanded={isExpanded}>
      <SideBarTitleSection isExpanded={isExpanded} onClick={onToggleSidebar} />
      <SideBarMenuButton
        isExpanded={isExpanded}
        isSelected={viewMode === "calendar"}
        onClick={handleClickCalendar}
        label={"Calendar"}
        icon={DateIcon}
        tooltipLabel={"Calendar"}
      />
      <SideBarMenuButton
        isExpanded={isExpanded}
        isSelected={viewMode === "wiki"}
        onClick={handleClickWiki}
        label={"Wiki"}
        icon={ProjectIcon}
        tooltipLabel={"Wiki"}
      />
    </SideBarGroupSection>
  );
};
