import React from "react";
import { Textarea, Text, Flex } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";

interface TaskDetailTitleProps {
  localTitle: string;
  titleRef: React.RefObject<HTMLTextAreaElement> | null | undefined;
  taskType: "Event" | "Task";
  isFullPage: boolean;
  isTitleFocused: boolean;
  setIsTitleFocused: React.Dispatch<React.SetStateAction<boolean>>;
  handleTitleFocus: () => void;
  handleChangeLocalTitle: (newTitle: string) => void;
  onSave: (localTitle: string) => void;
  updateTaskConfig: (key: string, value: any) => void;
}

const TaskDetailTitle = ({
  localTitle,
  titleRef,
  taskType,
  isFullPage,
  isTitleFocused = false,
  setIsTitleFocused,
  handleChangeLocalTitle,
  onSave,
  updateTaskConfig,
}: TaskDetailTitleProps) => {
  const handleResizeTitleHeight = () => {
    if (titleRef?.current) {
      titleRef.current.style.height = "auto";

      if (titleRef.current.scrollHeight < 98) {
        titleRef.current.style.height = titleRef.current.scrollHeight + "px";
      } else {
        titleRef.current.style.height = "98px";
      }
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleChangeLocalTitle(e.target.value);
    handleResizeTitleHeight();
  };

  const handleTitleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.stopPropagation();
    setIsTitleFocused(false);
    updateTaskConfig("title", localTitle);
  };

  const handleTitleFocus = (
    e: React.FocusEvent<HTMLTextAreaElement> | React.MouseEvent<HTMLParagraphElement>
  ) => {
    setIsTitleFocused(true);

    requestAnimationFrame(() => {
      const len = titleRef?.current?.value.length || 0;
      titleRef?.current?.setSelectionRange(len, len);
      handleResizeTitleHeight();
      titleRef?.current?.focus();
    });
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // NOTE 기본 동작(줄바꿈)을 방지
      setIsTitleFocused(false);
      updateTaskConfig("title", localTitle);
      onSave(localTitle);
    } else if (e.key === "Enter" && e.shiftKey) {
      e.stopPropagation();
    } else if (e.key === "Escape") {
      e.stopPropagation();
      setIsTitleFocused(false);
      titleRef?.current?.blur();
    }

    if (e.key === "Backspace") {
      e.stopPropagation();
    }
  };
  return (
    <Flex
      position="relative"
      width="100%"
      height={"max-content"}
      alignItems={isFullPage ? "center" : "flex-start"}
      flex={isFullPage ? "1 0 0" : "initial"}
      alignSelf="stretch"
    >
      {isTitleFocused ? (
        <Textarea
          ref={titleRef}
          value={localTitle}
          rows={1}
          variant="unstyled"
          fontSize={pxToRem(20)}
          lineHeight={pxToRem(30)}
          fontWeight={500}
          minHeight={pxToRem(38)}
          maxHeight={pxToRem(98)}
          height="auto"
          padding={`${pxToRem(4)} ${pxToRem(6)}`}
          overflow={"hidden"}
          color="white"
          borderRadius={pxToRem(5)}
          placeholder={`New ${taskType}`}
          _placeholder={{ color: "rgba(171, 173, 173, 0.4)" }}
          textOverflow="ellipsis"
          wordBreak={"break-all"}
          noOfLines={3}
          resize={"none"}
          onFocus={handleTitleFocus}
          _focus={{
            noOfLines: "initial",
            overflow: "auto",
            background: "#1a1c1c",
            outline: "none",
            animation: "inputActive 100ms cubic-bezier(0, 0, 1, 1) forwards",
          }}
          onKeyDown={handleTitleKeyDown}
          onBlur={handleTitleBlur}
          onChange={handleTitleChange}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        />
      ) : (
        <Text
          textStyle={"title2"}
          cursor="pointer"
          color="white"
          padding={`${pxToRem(4)} ${pxToRem(6)}`}
          borderRadius={pxToRem(5)}
          width="100%"
          noOfLines={3}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak={"break-all"}
          onClick={handleTitleFocus}
        >
          {localTitle ? localTitle : `New ${taskType}`}
        </Text>
      )}
    </Flex>
  );
};
export default TaskDetailTitle;
