import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";

import styles from "./Wiki.module.css";

import { projectListState } from "../../recoil/projects/projectListState";
import { useJuneTrackCall } from "../../utils/june/analytics";
import { ProejctListEmpty } from "./Project/ProejctListEmpty";

import { ProjectList } from "./Project/ProjectList";
import { selectedSpaceIdListState } from "../../recoil/spaces/selectedSpaceIdListState";
import { filterTasks } from "../../services/task/task.service";

export default function Wiki({ onProjectAddClick, loadData }) {
  const [currentStatusSeleted, setCurrentStatusSeleted] = useState("InProgress");
  const trackCall = useJuneTrackCall();
  const [selectedSpaces] = useRecoilState(selectedSpaceIdListState);
  const [projectList, updateProjectList] = useRecoilState(projectListState);

  const memoizedProjectList = useMemo(() => {
    const inProgressProjects = projectList
      ?.filter((project) => project.status === currentStatusSeleted)
      .filter((event) => filterTasks(selectedSpaces, event.id));

    if (inProgressProjects && inProgressProjects.length === 0) {
      return (
        <ProejctListEmpty
          onClick={onProjectAddClick}
          isDone={currentStatusSeleted !== "InProgress"}
        />
      );
    } else {
      return (
        <ProjectList
          projectData={inProgressProjects}
          onClick={onProjectAddClick}
          loadData={loadData}
        />
      );
    }
  }, [selectedSpaces, currentStatusSeleted, projectList]);

  useEffect(() => {
    trackCall("view_space_list");
  }, []);

  return (
    <div className={styles.wiki__body}>
      <div className={styles.wiki__header}>
        <div className={styles.headerInprogessAndDoneArea}>
          <div
            onClick={() => {
              setCurrentStatusSeleted("InProgress");
              trackCall("click_wiki_status", { status: "in progress" });
            }}
            className={`${styles.title} ${
              currentStatusSeleted === "InProgress" ? styles.titleSelected : null
            }`}
          >
            In Progress
          </div>
          <div
            onClick={() => {
              setCurrentStatusSeleted("Completed");
              trackCall("click_wiki_status", { status: "done" });
            }}
            className={`${styles.title} ${
              currentStatusSeleted === "Completed" ? styles.titleSelected : null
            }`}
          >
            Archived
          </div>
        </div>
        <div
          className={styles.headerCreateProject}
          onClick={() => {
            onProjectAddClick(true, null);
            trackCall("create_space", { location: "wiki" });
          }}
        >
          Create Space
        </div>
      </div>
      {memoizedProjectList}
    </div>
  );
}
