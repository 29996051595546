import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { COMPLETED } from "../../../constants/taskStateType";
import styles from "./TrashPopup.module.css";
import iconForVisibility from "../../../utils/taskDetail/visibility/iconForVisibility";
import trashRestore from "../../../assets/Trash/trash-restore.png";
import trashDeleteImg from "../../../assets/Trash/trash-delete.png";
import { projectListState } from "../../../recoil/projects/projectListState";
import { getSpaceColor } from "../../../services/space/space.service";

export const TrashTaskRow = ({ index, data, onDeleteClick, onRestoreClick }) => {
  const [projectList, setProjectList] = useRecoilState(projectListState);
  const [integrationImg, setIntegrationImg] = useState(null);
  const isDone = data.lastStatus === COMPLETED;

  useEffect(() => {
    const integrationData = data.integration?.provider;
    if (integrationData && integrationData !== "") {
      setIntegrationImg(integrationData);
    }
  }, [data]);

  const handleDeleteClick = () => {
    onDeleteClick(data.id, data.creator);
  };

  const handleRestoreClick = () => {
    onRestoreClick(data, data.creator);
  };

  const { projectId } = data;

  return (
    <div className={styles.project__item}>
      <div
        className={`${styles.taskboxBodyIcon} ${
          data.lastStatus === COMPLETED ? styles.taskListBodyIconDone : ""
        }`}
        style={{
          backgroundColor: `${`${getSpaceColor(projectList, projectId)}`}${isDone ? "" : "26"}`,
          boxShadow: `0 0 0 2px ${`${getSpaceColor(projectList, projectId)}`} inset`,
        }}
      ></div>
      <div className={styles.taskListBodyTitle}>{data.title}</div>
      {data.visibility && (
        <div className={styles.visibilityIcon}>
          {iconForVisibility(data.visibility, data.transparency)}
        </div>
      )}
      {integrationImg && (
        <div
          className={`${styles["integration"]} ${
            integrationImg === "gmail"
              ? styles["integration-gmail"]
              : integrationImg === "jira"
                ? styles["integration-jira"]
                : integrationImg === "slack" && styles["integration-slack"]
          } `}
        ></div>
      )}
      <div className={styles.project__delete} onClick={handleRestoreClick}>
        <img className={styles.project__delete__img} src={trashRestore} />
      </div>

      <div className={styles.project__delete} onClick={handleDeleteClick}>
        <img className={styles.project__delete} src={trashDeleteImg} />
      </div>
    </div>
  );
};
