import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useApi from "../../../services/auth/useApi";

interface FetchSpaceOrderType {
  projectIds: string[];
}

export const fetchSpaceOrderApi = async (api: ReturnType<typeof useApi>): Promise<string[]> => {
  const response = await api.get<FetchSpaceOrderType>("/projects/order");
  return response.data.projectIds;
};

export const spaceOrderQueryKey = () => ["space-order"];

export const useFetchProjectOrderQuery = (options?: UseQueryOptions<string[], AxiosError>) => {
  const api = useApi();

  return useQuery<string[], AxiosError>({
    queryKey: spaceOrderQueryKey(),
    queryFn: () => fetchSpaceOrderApi(api),
    ...options,
  });
};
