import React from "react";
import { Button, ButtonProps, Text } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";

export const ShowAllButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <Button
      h={"fit-content"}
      bgColor={hexToRgba("#ffffff", 0.08)}
      minW={"initial"}
      borderRadius={pxToRem(5)}
      px={1.5}
      py={1}
      _hover={{
        bgColor: hexToRgba("#ffffff", 0.16),
      }}
      {...props}
    >
      <Text
        color={"#D9D9D9"}
        textStyle={"body3"}
        _hover={{
          color: "#ffffff",
        }}
      >
        Show All
      </Text>
    </Button>
  );
};
