import { Center, Divider } from "@chakra-ui/react";
import { hexToRgba } from "../../utils/styles/color";
import React from "react";
const { useDragLayer } = require("react-dnd");

export function FilterDividerReorderDragLayer() {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!item || !isDragging || item.dragType !== "filterProject") {
    return null;
  }

  const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 1000,
    left: 0,
    top: 0,
    transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
    width: "256px",
    height: "17px",
    borderRadius: "6px",
    border: "1px solid var(--hover, rgba(255, 255, 255, 0.08))",
    background:
      "linear-gradient(0deg, var(--hover-big, rgba(255, 255, 255, 0.04)) 0%, var(--hover-big, rgba(255, 255, 255, 0.04)) 100%), #363A3A",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    boxSizing: "border-box",
    cursor: "pointer",
    paddingX: "2px",
    paddingY: "8px",
  };

  return (
    <div style={layerStyles} key={{ title: "project title" }}>
      <Center cursor={"pointer"} py={2} px={0.5} w={"full"}>
        <Divider color={hexToRgba("#ffffff", 0.24)} />
      </Center>
    </div>
  );
}
