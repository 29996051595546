import React from "react";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../icons";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";

interface SideBarToggleButtonProps {
  onClick: () => void;
  isExpanded: boolean;
}
export const SideBarToggleButton: React.FC<SideBarToggleButtonProps> = ({
  onClick,
  isExpanded,
}) => {
  return (
    <Tooltip label={`${isExpanded ? "Close" : "Open"} sidebar`} placement={"right"}>
      <IconButton
        role={"group"}
        icon={
          isExpanded ? (
            <ArrowLeftIcon
              color={"#d9d9d9"}
              _groupHover={{
                color: "white",
              }}
            />
          ) : (
            <ArrowRightIcon
              color={"#d9d9d9"}
              _groupHover={{
                color: "white",
              }}
            />
          )
        }
        onFocus={(e) => {
          e.currentTarget.blur();
          e.preventDefault();
        }}
        aria-label={`${isExpanded} ? close sidebar : open sidebar`}
        onClick={onClick}
        h={pxToRem(26)}
        variant="outline"
        fontSize={pxToRem(16)}
        borderRadius={pxToRem(5)}
        border={"1px solid"}
        borderColor={"#ffffff14"}
        minWidth={"initial"}
        boxSize={pxToRem(26)}
        _hover={{
          bg: hexToRgba("#ffffff", 0.08),
        }}
        _active={{}}
        _focus={{
          outline: "none",
          boxShadow: "none",
        }}
      />
    </Tooltip>
  );
};
