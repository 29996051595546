import React, { useEffect, useState } from "react";
import { Flex, Input, Text } from "@chakra-ui/react";
import { SideBarSpaceAddButton } from "./SideBarSpaceAddButton";
import { hexToRgba } from "../../../utils/styles/color";
import { pxToRem } from "../../../utils/styles/size";
import { ShowAllButton } from "./ShowAllButton";
import { isDividerInput } from "../../../services/divider/divider.service";
import { ShowAllMinButton } from "./ShowAllMinButton";

interface SpaceItemTitleSectionProps {
  isExpanded: boolean;
  shouldShowAll: boolean;
  onCreateSpace: () => void;
  onShowAll: () => void;
  onSubmit: (newTitle: string) => void;
  onCreateDivider: () => void;
  isInputVisible: boolean;
  setInputVisible: (value: boolean) => void;
}

export const SpaceItemTitleSection: React.FC<SpaceItemTitleSectionProps> = ({
  onCreateSpace,
  shouldShowAll,
  onShowAll,
  isExpanded,
  onSubmit,
  onCreateDivider,
  isInputVisible,
  setInputVisible,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInput = e.target.value;
    setInputValue(newInput);
    if (isDividerInput(newInput)) {
      setInputValue("");
      onCreateDivider();
    }
  };

  const resetInput = () => {
    setInputVisible(false);
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      resetInput();
      return;
    }
    if (inputValue.trim() === "" || event.key !== "Enter") return;
    onSubmit(inputValue);
    setInputValue("");
  };

  useEffect(() => {
    return () => {
      setInputValue("");
    };
  }, []);

  const showMinAllButton = !isExpanded && shouldShowAll;

  return (
    <>
      <Flex
        justify={isExpanded ? "space-between" : "center"}
        alignItems={"center"}
        px={1}
        minH={pxToRem(36)}
        w={"full"}
      >
        <Text display={isExpanded ? "block" : "none"} textStyle={"body1"}>
          Spaces
        </Text>
        <Flex gap={2} alignItems={"center"} justifyContent={"center"}>
          {shouldShowAll && isExpanded && <ShowAllButton onClick={onShowAll} />}
          {!showMinAllButton && !isInputVisible && (
            <SideBarSpaceAddButton onClick={onCreateSpace} isInputVisible={isInputVisible} />
          )}
          {showMinAllButton && <ShowAllMinButton onClick={onShowAll} />}
        </Flex>
      </Flex>
      <>
        {isInputVisible && (
          <Input
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyPress}
            bg={hexToRgba("#000000", 0.2)}
            borderRadius={5}
            border={"none"}
            h={"fit-content"}
            px={2}
            py={1}
            color="white"
            autoFocus
            aria-label="New space name"
            boxSizing={"border-box"}
            _focusVisible={{}}
            // TODO: text style not working in input tag
            fontWeight={400}
            fontSize={pxToRem(14)}
            lineHeight={pxToRem(21)}
            onBlur={resetInput}
            sx={{
              caretColor: "#69E4FF",
            }}
          />
        )}
      </>
    </>
  );
};
