import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";

import { v4 as uuid4 } from "uuid";
import { projectListState } from "../../../recoil/projects/projectListState";
import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";
import useApi from "../../../services/auth/useApi";
import { useJuneTrackCall } from "../../../utils/june/analytics";

import ProjectAddModal from "./ProjectAddModal";
import PropertyTitle from "../Common/PropertyTitle";

import { ReactComponent as ProjectIcon } from "../../../assets/TaskDetail/project.svg";
import { ReactComponent as AddIcon } from "../../../assets/Common/add-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/TaskDetail/delete-icon.svg";

import clsx from "clsx";

import styles from "./ProjectModal.module.css";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";
import {
  createProjectPayload,
  getNextAvailableSpaceColor,
  getSpaceColor,
} from "../../../services/space/space.service";
import { useCreateSpaceMutation } from "../../../react-query/space/core/useCreateSpaceMutation";

const ProjectRow = ({ title, projectId, color, onClick }) => {
  return (
    <div className={styles["projectRow-body"]} onClick={() => onClick(title, projectId)}>
      <div
        className={styles["projectRow-body-icon"]}
        style={{
          backgroundColor: "" + color,
          boxShadow: "0 0 0 2px " + color + " inset",
        }}
      ></div>
      <div className={styles["projectRow-body-title"]}>{title}</div>
    </div>
  );
};

const AddBtn = ({ text, onClick }) => {
  return (
    <>
      <div className={styles["projectRow-add"]} onClick={onClick}>
        <AddIcon className={styles["projectRow-add-icon"]} />
        <span className={styles["projectRow-add-title"]}>{text}</span>
      </div>
    </>
  );
};

export default function ProjectModal({ loadData, expand, projectDropdownRef }) {
  // Recoil states
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const [projectList, updateProjectList] = useRecoilState(projectListState);
  const [selectedSpaces, setSelectedSpaces] = useRecoilState(selectedSpaceIdListState);
  // React states
  const [isProjectAddModal, setIsProjectAddModal] = useState(false);
  const [isProjectHover, setIsProjectHover] = useState(false);
  const [isPropertyModal, setIsPropertyModal] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const [projectListModalPosition, setProjectListModalPosition] = useState({
    top: 0,
    left: 0,
    width: 320,
  });
  const { mutate: createSpace } = useCreateSpaceMutation();

  // Memoized values
  const data = useMemo(() => taskDetail.data, [taskDetail]);

  // Custom hooks
  const trackCall = useJuneTrackCall();
  const api = useApi();

  // Refs
  const wrapRef = useRef(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (
        projectDropdownRef.current &&
        !projectDropdownRef.current.contains(event.target) &&
        !triggerRef.current.contains(event.target)
      ) {
        setIsPropertyModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [projectDropdownRef]);

  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setIsScrolling(true);
    const timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 2000);

    setScrollTimeout(timeout);
  };

  useEffect(() => {
    const element = document.querySelector(`.${styles.wrap}`);
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  const handleSelectProject = (title, projectId) => {
    setIsPropertyModal(false);
    updateTaskConfig("projectId", projectId);
  };

  const updateTaskConfig = (key, value) => {
    setTaskDetail((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: value,
      },
    }));
  };

  const handleProjectAdd = () => {
    setIsProjectAddModal(true);
  };

  const handleAddProject = (newProjectTitle) => {
    const email = data.creator;
    const newItem = createProjectPayload(email, newProjectTitle, projectList);

    createSpace(newItem, {
      onSuccess: () => {
        trackCall("create_space", { location: "task_page" });
      },
    });
  };

  const handleDeleteProject = (event) => {
    event.stopPropagation();

    setTaskDetail((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        projectId: "",
      },
    }));
  };

  const handleIsPropertyModal = (event) => {
    event.stopPropagation();
    setIsPropertyModal(!isPropertyModal);
    handleSetModalPosition(event);
  };

  const handleKeyDown = (event) => {
    // taskDetail 꺼지지 않도록 이벤트 전파 막기
    event.stopPropagation();

    if (event.key === "Escape") {
      setIsPropertyModal(false);

      if (document.activeElement) {
        document.activeElement.blur();
      }
    }
  };

  const handleSetModalPosition = (event) => {
    event.stopPropagation();
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setProjectListModalPosition({
        top: rect.top + rect.height + 5 + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  };
  return (
    <>
      <div className={styles["setting-property-btn"]} onKeyDown={handleKeyDown}>
        <PropertyTitle Icon={ProjectIcon} label="Projects" expand={expand} />
        <div
          className={clsx(styles["setting-property"], {
            [styles["setting-property--exapnd"]]: expand,
          })}
          ref={triggerRef}
        >
          {data.projectId ? (
            <div
              className={clsx(styles.selected_project, {
                [[styles.selected_project_mini]]: !expand,
              })}
              style={{
                backgroundColor: "" + getSpaceColor(projectList, data.projectId) + "40",
                border: "1px solid" + getSpaceColor(projectList, data.projectId),
              }}
            >
              <span
                className={styles["projectRow-body-icon"]}
                style={{
                  backgroundColor: "" + getSpaceColor(projectList, data.projectId),
                  boxShadow: "0 0 0 2px " + getSpaceColor(projectList, data.projectId) + " inset",
                }}
              ></span>
              <span
                className={`${styles["setting-property-label"]} ${data.projectId && styles["label-on"]}`}
              >
                {projectList.filter((project) => project.id === data.projectId)[0]?.title}
              </span>

              <div className={styles.selectedGuestRowDelete} onClick={handleDeleteProject}>
                <DeleteIcon className={styles.selectedGuestRowDeleteIcon} />
              </div>
            </div>
          ) : (
            <button className={styles.property__empty_title} onClick={handleIsPropertyModal}>
              <span>Add a space</span>
            </button>
          )}

          {isPropertyModal &&
            createPortal(
              <div
                className={clsx(styles["wrap"], { [styles["showScrollbar"]]: isScrolling })}
                ref={projectDropdownRef}
                style={projectListModalPosition}
              >
                {projectList
                  .filter((projectDataRow) => projectDataRow.status === "InProgress")
                  .map((item) => (
                    <ProjectRow
                      key={item.id}
                      title={item.title}
                      projectId={item.id}
                      color={item.color}
                      onClick={handleSelectProject}
                    />
                  ))}
                <AddBtn text="New Space" onClick={handleProjectAdd} />
                {isProjectAddModal && (
                  <ProjectAddModal
                    onSave={(newProjectTitle) => {
                      handleAddProject(newProjectTitle);
                    }}
                    onClose={() => setIsProjectAddModal(false)}
                  />
                )}
              </div>,
              document.body
            )}
        </div>
      </div>
    </>
  );
}
