export const deleteNullValueInObject = <T extends Record<string, unknown>>(obj: T): T => {
  const nullDeletedData = Object.entries(obj).reduce((acc: T, [key, value]) => {
    if (value !== null) {
      acc[key as keyof T] = value as T[keyof T];
    }
    return acc;
  }, {} as T);

  return nullDeletedData;
};
