import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { pxToRem } from "../utils/styles/size";

const baseStyle = {
  borderRadius: pxToRem(3),
  color: "#D9D9D9",
  bg: "#545959",
  textStyle: "tooltip",
  textAlign: "center",
  verticalAlign: "center",
  fontSize: "0.6875rem",
};

const sizes = {
  default: defineStyle({
    py: pxToRem(2),
    px: pxToRem(5),
    maxW: "none",
    textStyle: "tooltip",
    fontSize: "0.6875rem",
  }),
};

type TooltipDefaultProps = {
  size: "default";
  variant: "default";
  colorScheme: "gray";
};

const defaultProps: TooltipDefaultProps = {
  size: "default",
  variant: "default",
  colorScheme: "gray",
};

export const tooltipTheme = defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps,
});
