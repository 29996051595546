import axios from "axios";

import { useRecoilState, useRecoilValue } from "recoil";
import { tokenState } from "../../recoil/auth/tokenState";
import useApi from "../auth/useApi";

import { saveToLocalStorage } from "../../utils/localStorage/localStorage";

import { useNavigate } from "react-router-dom";
import apiLogger from "../../utils/log/apiLogger";
import styledConsole from "../../utils/log/styledConsole";
import { useRef, useEffect } from "react";
import { accountState } from "../../recoil/account/accountStateV2";

const isDev = process.env.NODE_ENV !== "production";

const useApiCalendar = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const navigate = useNavigate();
  const authApi = useApi();
  const accountData = useRecoilValue(accountState);
  const tokenRef = useRef(token);

  useEffect(() => {
    tokenRef.current = token;
  }, [token, setToken]);

  const calendarApi = axios.create({
    baseURL: "https://www.googleapis.com/calendar/v3/calendars/primary/events",
    headers: {
      Authorization: `Bearer ${tokenRef.current.accessToken}`,
    },
  });

  const refreshGoogleCalendarAccessToken = async () => {
    try {
      const response = await authApi.post(`auth/oauth2/refresh`, {
        kind: "account",
        key: accountData.accountInfo.accounts[0].email,
      });

      const newToken = {
        ...tokenRef.current,
        accessToken: response.data.accessToken,
      };

      setToken(newToken);

      saveToLocalStorage("tokenState", newToken);

      return newToken;
    } catch (error) {
      console.error("토큰 갱신 중 오류 발생:", error);
      setToken({
        mAccessToken: null,
        mRefreshToken: null,
        accessToken: null,
      });

      // saveToLocalStorage("tokenState", {
      //   mAccessToken: null,
      //   mRefreshToken: null,
      //   accessToken: null,
      // });

      if (error.response && error.response.status === 500) {
        window.location.href = "/join";
      } else {
        window.location.href = "/";
      }

      throw error;
    }
  };

  calendarApi.interceptors.response.use(
    (res) => {
      const { status, config: reqData, data: resData } = res;
      if (isDev) apiLogger({ status, reqData, resData });
      return res;
    },
    async (error) => {
      try {
        const { response: res, config: reqData } = error || {};
        const { status } = res || {};
        const isUnAuthError = status === 401;
        const isExpiredToken = status === 444;

        if (isDev) {
          apiLogger({ status, reqData, resData: error, method: "error" });
        }
        if (isExpiredToken || isUnAuthError) {
          console.log("Token Expired");
          try {
            const newToken = await refreshGoogleCalendarAccessToken();
            setToken({
              mAccessToken: newToken.mAccessToken,
              mRefreshToken: newToken.mRefreshToken,
              accessToken: newToken.accessToken,
            });

            reqData.headers.Authorization = `Bearer ${newToken.mAccessToken}`;

            saveToLocalStorage("tokenState", newToken);
            return axios(reqData);
          } catch (refreshError) {
            console.error("Failed to refresh token:", refreshError);
            setToken({
              mAccessToken: null,
              mRefreshToken: null,
              accessToken: null,
            });
            navigate("/join");
          }
        }

        return Promise.reject(error);
      } catch (e) {
        styledConsole({
          method: "error",
          topic: "UN_HANDLED",
          title: "axios-interceptor",
          data: e,
        });
      }
    }
  );
  return calendarApi;
};

export default useApiCalendar;
