import { useState } from "react";
import styles from "./TrashPopup.module.css";
import trashDeleteImg from "../../../assets/Trash/trash-delete.png";
import trashRestore from "../../../assets/Trash/trash-restore.png";

export const TrashProjectRow = ({ index, data, onDeleteClick, onRestoreClick }) => {
  const [rowIcon, setRowIcon] = useState(true);

  const handleDeleteClick = () => {
    onDeleteClick(data.id);
  };

  const handleRestoreClick = () => {
    onRestoreClick(data);
  };

  return (
    <div className={styles.project__item}>
      <div
        className={styles.project__icon}
        style={{
          backgroundColor: `${data.color}${!rowIcon ? "26" : ""}`,
          boxShadow: "0 0 0 2px " + data.color + " inset",
        }}
        onClick={() => setRowIcon(!rowIcon)}
      />
      <div className={styles.project__title}>{data.title}</div>
      <div className={styles.project__delete}></div>
      <div className={styles.project__delete} onClick={handleRestoreClick}>
        <img className={styles.project__delete__img} src={trashRestore} alt="restore" />
      </div>

      <div className={styles.project__delete} onClick={handleDeleteClick}>
        <img className={styles.project__delete} src={trashDeleteImg} alt="trash" />
      </div>
    </div>
  );
};
