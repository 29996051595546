import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { SpaceType } from "../../../types/space";
import useApi from "../../../services/auth/useApi";
import { useRecoilState } from "recoil";
import { projectListState } from "../../../recoil/projects/projectListState";
import { useEffect } from "react";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";
import { NoSpaceFilter } from "../../../constants/space";

interface FetchSpaceResponse {
  projects: SpaceType[];
}

export const fetchSpaceApi = async (api: ReturnType<typeof useApi>): Promise<SpaceType[]> => {
  const response = await api.get<FetchSpaceResponse>("/projects");
  return response.data.projects;
};

export const spaceQueryKey = () => ["space"];

export const useFetchSpaceQuery = (options?: UseQueryOptions<SpaceType[], AxiosError>) => {
  const api = useApi();
  const [_, setProjectList] = useRecoilState(projectListState);
  const [selectedSpaces, setSelectedSpaces] = useRecoilState(selectedSpaceIdListState);

  const query = useQuery<SpaceType[], AxiosError>({
    queryKey: spaceQueryKey(),
    queryFn: () => fetchSpaceApi(api),
    ...options,
  });

  useEffect(() => {
    if (query.isSuccess && query.data && selectedSpaces === null) {
      const newSelectedSpaces = query.data.map((project) => project.id);
      setSelectedSpaces([...newSelectedSpaces, NoSpaceFilter]);
    }
  }, [query.data, query.isSuccess, selectedSpaces, setSelectedSpaces]);

  useEffect(() => {
    if (query.isSuccess && query.data) {
      setProjectList(query.data);
    }
  }, [query.data, query.isSuccess, setProjectList]);

  return query;
};
