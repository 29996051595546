import { NoSpaceFilter } from "../constants/space";

export interface SpaceType {
  id: string | typeof NoSpaceFilter;
  title: string;
  color: string;
  status: ProjectStatus;
  creator: string;
  description?: string;
  bookmarks?: object[]; // TODO: replace object into exact interface
  createdAt?: string;
}

export interface NoSpaceType {
  id: typeof NoSpaceFilter;
  color: string;
}

export interface CreateSpaceType extends Omit<SpaceType, "bookmarks" | "createdAt"> {}
export interface UpdateSpaceType extends Partial<CreateSpaceType> {}

export enum ProjectStatus {
  "InProgress" = "InProgress",
  "Completed" = "Completed",
  "Deleted" = "Deleted",
}

export interface CreateSpaceBookmarkType {
  id: string;
  title: string;
  kind: string;
  data: {
    link: string;
  };
}

export interface DividerType {
  id: string;
  category: ProjectCategory.ProjectDivider;
  color?: string;
  creator: string;
}

export type CreateDividerType = DividerType;

export enum ProjectCategory {
  "ProjectDivider" = "project-divider",
}
