import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKey } from "../core/useFetchSpaceQuery";
import { DividerType, SpaceType } from "../../../types/space";
import { useRecoilState } from "recoil";
import { projectListState } from "../../../recoil/projects/projectListState";

const deleteDividerApi = async (
  api: ReturnType<typeof useApi>,
  dividerId: string
): Promise<void> => {
  await api.delete(`/projects/dividers/${dividerId}`);
};

export const useDeleteDividerMutation = (
  options?: UseMutationOptions<void, AxiosError, string>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [projectList, setProjectList] = useRecoilState(projectListState);

  return useMutation<void, AxiosError, string>({
    mutationFn: (dividerId) => deleteDividerApi(api, dividerId),
    onMutate: async (dividerId) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKey() });

      if (projectList) {
        const updatedProjectList = projectList.filter((project) => {
          return project.id !== dividerId;
        });
        setProjectList(updatedProjectList);
      }
      return { dividerId };
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
