import { areDateTimeEqual } from "../../common/dateTime/areDateTimeEqual";

export const isTaskTypeChanged = (initialTaskType: "Task" | "Event", isCurrentTask: boolean) => {
  return isCurrentTask !== (initialTaskType === "Task");
};

export const isVisibilityChanged = (initialVisibility: string, newVisibility: string) => {
  return initialVisibility !== newVisibility;
};
export const isTransparencyChanged = (
  initialTransparency: string,
  newTransparency: string
): boolean => {
  return initialTransparency !== newTransparency;
};

export const isTotalVisibilityChanged = (
  initialVisibility: string,
  newVisibility: string,
  initialTransparency: string,
  newTransparency: string
): boolean => {
  return (
    isVisibilityChanged(initialVisibility, newVisibility) ||
    isTransparencyChanged(initialTransparency, newTransparency)
  );
};

export const isTitleChanged = (initialTitle: string, currentTitle: string): boolean => {
  return initialTitle !== currentTitle;
};

export const isProjectIdChanged = (initialProjectId: string, newProjectId: string): boolean => {
  return initialProjectId !== newProjectId;
};

export const isStartTimeChanged = (initialTime: Date, currentTime: Date): boolean => {
  return !areDateTimeEqual(new Date(initialTime), new Date(currentTime));
};

export const isEndTimeChanged = (initialTime: Date, currentTime: Date): boolean => {
  return !areDateTimeEqual(new Date(initialTime), new Date(currentTime));
};

export const isRecurrenceChanged = (
  initialRecurrence: string,
  currentRecurrence: string
): boolean => {
  return initialRecurrence !== currentRecurrence;
};

export const isVideoChanged = (initialLink: string, currentLink: string): boolean => {
  return initialLink !== currentLink;
};

export function isAttendeesChanged(
  originalAttendees: { email: string }[] | undefined,
  newAttendees: { email: string }[] | undefined
): boolean {
  const safeOriginal = originalAttendees || [];
  const safeNew = newAttendees || [];

  if (safeOriginal.length !== safeNew.length) {
    return true;
  }

  const originalEmails = new Set(safeOriginal.map((attendee) => attendee.email));
  const newEmails = new Set(safeNew.map((attendee) => attendee.email));

  if (originalEmails.size !== newEmails.size) {
    return true;
  }

  return Array.from(originalEmails).some((email) => !newEmails.has(email));
}

export const areLinksChanged = (
  initialLinks: { url: string }[] | undefined,
  newLinks: { url: string }[] | undefined
): boolean => {
  if (initialLinks && initialLinks.length > 0) {
    if (!newLinks || initialLinks.length !== newLinks.length) {
      return true;
    }
    return initialLinks.some((link, idx) => !newLinks[idx] || link.url !== newLinks[idx].url);
  }

  if (newLinks && newLinks.length > 0) {
    return true;
  }
  return false;
};

// TODO 추후 event type 추가
export const shouldNotifyGuests = (initialData: any, updateTask: any): boolean => {
  return (
    isTitleChanged(initialData.title, updateTask.title) ||
    isStartTimeChanged(initialData.start, updateTask.start.dateTime || updateTask.start.date) ||
    isEndTimeChanged(initialData.end, updateTask.end.dateTime || updateTask.end.date) ||
    isRecurrenceChanged(initialData.recurrence, updateTask.recurrence) ||
    isAttendeesChanged(initialData.attendees, updateTask.attendees) ||
    isVideoChanged(initialData.hangoutLink, updateTask.hangoutLink)
  );
};

export const isAnyDataChangedInBlock = (
  initialData: any,
  updateTask: any,
  isCurrentTask: boolean
): boolean => {
  return (
    isTotalVisibilityChanged(
      initialData.visibility,
      updateTask.visibility,
      initialData.transparency,
      updateTask.transparency
    ) ||
    isTitleChanged(initialData?.title, updateTask?.title) ||
    isProjectIdChanged(initialData?.projectId, updateTask?.projectId) ||
    isStartTimeChanged(
      initialData?.start,
      updateTask?.start?.dateTime || updateTask?.start?.date
    ) ||
    isEndTimeChanged(initialData?.end, updateTask?.end?.dateTime || updateTask?.end?.date) ||
    isRecurrenceChanged(initialData?.recurrence, updateTask?.recurrence) ||
    isAttendeesChanged(initialData?.attendees, updateTask?.attendees) ||
    areLinksChanged(initialData.links, updateTask.links) ||
    isVideoChanged(initialData?.hangoutLink, updateTask?.hangoutLink)
  );
};
