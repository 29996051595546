import { useEffect, useRef, useState } from "react";
import styles from "../../ProjectCreate.module.css";
import { TimelineEmpty } from "../TimelineEmpty/TimelineEmpty";
import { Month } from "../TimelineMonth/Month";
import Timeblock from "../../Timeblock";

export function TimeLine({ dotColor, calendarTaskEvent }) {
  const parentRef = useRef(null);
  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    const updateParentHeight = () => {
      if (parentRef.current) {
        setParentHeight(parentRef.current.scrollHeight);
      }
    };

    updateParentHeight();

    window.addEventListener("resize", updateParentHeight);

    return () => {
      window.removeEventListener("resize", updateParentHeight);
    };
  }, [calendarTaskEvent]);

  return (
    <>
      <div className={styles.project__timeline} ref={parentRef}>
        <div className={styles.timeline__vertical} style={{ height: parentHeight }}></div>
        <div className={styles.timeBlockListArea}>
          {calendarTaskEvent == null || Object.keys(calendarTaskEvent).length === 0 ? (
            <TimelineEmpty dotColor={dotColor} />
          ) : (
            Object.keys(calendarTaskEvent)
              .sort((a, b) => new Date(a) - new Date(b))
              .map((date) => (
                <div key={date} className={styles.timeBlockArea}>
                  <div className={styles.monthArea}>
                    <Month data={date} />
                  </div>
                  {calendarTaskEvent[date].map((data, index) => (
                    <Timeblock key={index} dotColor={dotColor} data={data} />
                  ))}
                </div>
              ))
          )}
        </div>
      </div>
    </>
  );
}
