import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { CreateSpaceType } from "../../../types/space";
import { spaceQueryKey } from "./useFetchSpaceQuery";
import { projectListState } from "../../../recoil/projects/projectListState";
import { useRecoilState } from "recoil";

export const createSpaceApi = async (
  api: ReturnType<typeof useApi>,
  payload: CreateSpaceType
): Promise<CreateSpaceType> => {
  const response = await api.post("/projects", payload);
  return response.data;
};

export const useCreateSpaceMutation = (
  options?: UseMutationOptions<CreateSpaceType, AxiosError, CreateSpaceType>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [projectList, setProjectList] = useRecoilState(projectListState);

  return useMutation<CreateSpaceType, AxiosError, CreateSpaceType>({
    mutationFn: (payload) => createSpaceApi(api, payload),
    onMutate: async (newSpace) => {
      await queryClient.cancelQueries({ queryKey: spaceQueryKey() });
      const newSpaces = projectList ? [newSpace, ...projectList] : [newSpace];
      setProjectList(newSpaces);
      return { projectList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
