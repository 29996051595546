import { useEffect, useRef, useState } from "react";
import DoneModal from "./DoneModal";
import DoneTaskList from "./DoneTaskList";
import styles from "./styles.module.css";
import TaskList from "./TaskList";
import { loadFromLocalStorage } from "../../utils/localStorage/localStorage";
import { MoreButton } from "./MoreButton";

export default function TaskInbox({
  loadDoneTask,
  loadInboxTask,
  toggleIntegrationModal,
  inboxExpand,
  setInboxExpand,
  setIsIntegrationModalOpen,
  isIntegrationModalOpen,
  integrationBtnRef,
  setIsIntegrationDataFirstLoading,
  setMobaEventList,
}) {
  const [isOpenMore, setIsOpenMore] = useState(false);
  const [currentMoreType, setCurrentMoreType] = useState(null);
  const doneModalRef = useRef(null);

  useEffect(() => {
    if (loadFromLocalStorage("moreType")) {
      setCurrentMoreType(loadFromLocalStorage("moreType"));
    }
  }, []);

  const handleExpandClick = () => {
    setInboxExpand((current) => !current);
    setIsIntegrationModalOpen(false);
  };

  const handleIntegrationClick = () => {
    toggleIntegrationModal();
    !isIntegrationModalOpen && setIsIntegrationDataFirstLoading(true);
    setTimeout(() => {
      setIsIntegrationDataFirstLoading(false);
    }, 3000);
  };

  return (
    <div
      className={`${styles["taskInbox"]}
        ${inboxExpand && styles["taskInbox-default"]}
        ${!inboxExpand && styles["taskInbox-expand"]}`}
    >
      <div className={`${styles["title"]}`}>
        <div className={styles.titleRow}>
          <div className={`${styles["title-icon"]}`} onClick={handleExpandClick}>
            <div
              className={`${styles["title-icon-hover"]} ${
                !inboxExpand && styles["title-icon-rotate"]
              }`}
            ></div>
            <div className={styles["title-icon-default"]}></div>
          </div>
          {!inboxExpand && (
            <>
              <div className={styles["title-main"]}>Inbox</div>
              <div ref={integrationBtnRef}>
                {/* <IntegrationIcon
                  className={`${styles.integration__btn} ${
                    isIntegrationModalOpen && styles.integration__btn_active
                  }`}
                  onClick={handleIntegrationClick}
                /> */}
              </div>
            </>
          )}

          {!inboxExpand && (
            <div className={styles.moreWrapper}>
              <MoreButton
                openMore={isOpenMore}
                onClick={() => setIsOpenMore((current) => !current)}
                ref={doneModalRef}
              />
              {isOpenMore && (
                <DoneModal
                  onClose={() => setIsOpenMore(false)}
                  handleCurrentMoreType={(type) => setCurrentMoreType(type)}
                  currentMoreType={currentMoreType}
                  doneModalRef={doneModalRef}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <TaskList
        expand={!inboxExpand}
        onExpandClick={handleExpandClick}
        loadInboxTask={loadInboxTask}
        setMobaEventList={setMobaEventList}
      />
      {currentMoreType && (
        <DoneTaskList
          expand={!inboxExpand}
          onExpandClick={handleExpandClick}
          loadDoneTask={loadDoneTask}
          setMobaEventList={setMobaEventList}
        />
      )}
    </div>
  );
}
