import { useRecoilState } from "recoil";
import styles from "./MeetWithPopup.module.css";
import {
  useAccountContactsQuery,
  useAccountContactsRecentQuery,
  useUpdateContactsRecent,
} from "../../queries/Accounts";
import { meetWithAccountsState } from "../../recoil/account/accountState";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { MeetWithColors } from "../../constants";
import { ReactComponent as ProfileIcon } from "../../assets/Header/profile.svg";
import { accountState } from "../../recoil/account/accountStateV2";

function MeetWithPopup({ onClose, ...props }, ref) {
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [meetWithAccounts, setMeetWithAccounts] = useRecoilState(meetWithAccountsState);

  const accountContactsRecentQuery = useAccountContactsRecentQuery();
  const accountContactsQuery = useAccountContactsQuery();
  const updateContactsRecent = useUpdateContactsRecent();

  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    const escKeyModalClose = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", escKeyModalClose);
    return () => window.removeEventListener("keydown", escKeyModalClose);
  }, [onClose]);

  useEffect(() => {
    setCurrentActiveIndex(0);
  }, [inputRef.current?.value]);

  const convertNotIncludeMeetWithAccount = (prevList) => {
    return meetWithAccounts.length
      ? prevList.filter(
          (item) =>
            !meetWithAccounts.some((meetWithAccount) => meetWithAccount.email === item.email)
        )
      : prevList;
  };

  const contactList = useMemo(() => {
    if (!accountContactsQuery.data || !accountData) return [];
    const [primaryAccountInfo] = accountData.accountInfo.accounts.filter((account) => {
      return account.type === "primary";
    });
    const creator = primaryAccountInfo.email;
    return accountContactsQuery.data.contacts[creator];
  }, [accountContactsQuery.data, accountData]);

  const defaultList = useMemo(() => {
    if (!accountContactsRecentQuery.data || !contactList) return [];

    return convertNotIncludeMeetWithAccount(
      accountContactsRecentQuery.data?.emails?.map((email) => {
        const foundContact = contactList?.find((contactAccount) => contactAccount.email === email);
        return foundContact ?? { email };
      })
    );
  }, [contactList, accountContactsRecentQuery.data, meetWithAccounts]);

  const handleChangeSearchAccountInput = (e) => {
    if (e.target.value === "") {
      setFilteredAccounts([]);
      return;
    }
    if (contactList.length === 0) return;

    setFilteredAccounts(
      convertNotIncludeMeetWithAccount(
        contactList.filter((guest) => guest.email.includes(e.target.value))
      )
    );
  };

  const handleClickAccount = (account) => {
    inputRef.current.value = "";
    updateContactsRecent.mutate(account.email);
    setMeetWithAccounts([
      ...meetWithAccounts,
      {
        ...account,
        isAccess: true,
        color: MeetWithColors[meetWithAccounts.length % MeetWithColors.length],
      },
    ]);
    setCurrentActiveIndex(0);
  };

  const handleActiveIndex = (activeIndex) => {
    setCurrentActiveIndex(activeIndex);
  };

  const handleRemoveMeetWithAccount = (account) => {
    setMeetWithAccounts(
      meetWithAccounts
        .filter((meetWithAccount) => meetWithAccount.email !== account.email)
        .map((account, idx) => ({ ...account, color: MeetWithColors[idx % MeetWithColors.length] }))
    );
  };

  return (
    <div className={styles.meetWithListWrapper} ref={ref} {...props}>
      <div className={styles.searchInputWrapper}>
        <input
          ref={inputRef}
          autoFocus
          type="text"
          placeholder="Search for people"
          onChange={handleChangeSearchAccountInput}
        />
      </div>
      {meetWithAccounts.length > 0 && (
        <MeetWithList
          accounts={meetWithAccounts}
          onAccountClick={handleRemoveMeetWithAccount}
          isMeetWith
        />
      )}
      {inputRef.current?.value ? (
        <AccountList
          accounts={filteredAccounts}
          onAccountClick={handleClickAccount}
          currentActiveIndex={currentActiveIndex}
          handleActiveIndex={handleActiveIndex}
        />
      ) : (
        defaultList.length > 0 && (
          <AccountList
            accounts={defaultList}
            onAccountClick={handleClickAccount}
            currentActiveIndex={currentActiveIndex}
            handleActiveIndex={handleActiveIndex}
          />
        )
      )}
    </div>
  );
}

export default forwardRef(MeetWithPopup);

function AccountList({ accounts, onAccountClick, handleActiveIndex, currentActiveIndex }) {
  const accountRef = useRef([]);

  const handleScrollActiveIndex = (activeIndex) => {
    if (!accountRef.current) return;

    handleActiveIndex(activeIndex);
    accountRef.current[activeIndex].scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case "Enter":
          e.stopPropagation();
          if (accounts[currentActiveIndex]) {
            return onAccountClick(accounts[currentActiveIndex]);
          }
          break;
        case "ArrowDown":
          if (currentActiveIndex >= accounts.length - 1) {
            return handleScrollActiveIndex(0);
          }
          handleScrollActiveIndex(currentActiveIndex + 1);
          break;
        case "ArrowUp":
          if (currentActiveIndex <= 0) {
            return handleScrollActiveIndex(accounts.length - 1);
          }
          handleScrollActiveIndex(currentActiveIndex - 1);
          break;

        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [currentActiveIndex, accounts]);

  return (
    <ul className={styles.accountList}>
      {accounts.length > 0 ? (
        accounts.map((account, idx) => (
          <li
            key={account.email || `account-${idx}`}
            ref={(el) => {
              accountRef.current[idx] = el;
            }}
            className={`${styles.accountItem} ${currentActiveIndex !== undefined && currentActiveIndex === idx && styles.focusList}`}
            onClick={(e) => {
              e.stopPropagation();
              onAccountClick(account);
            }}
            onMouseEnter={() => {
              handleActiveIndex && handleActiveIndex(idx);
            }}
          >
            <div className={styles.accountImg}>
              {account.photoUrl ? (
                <img src={account.photoUrl} alt={account.name} />
              ) : (
                <ProfileIcon />
              )}
            </div>
            {account.name === account.email || !account.name ? (
              <p className={styles.accountName}>{account.email}</p>
            ) : (
              <>
                <p className={styles.accountName}>{account.name}</p>
                <p className={styles.accountEmail}>({account.email})</p>
              </>
            )}
          </li>
        ))
      ) : (
        <li className={styles.notFound}>Nothing found</li>
      )}
    </ul>
  );
}

function MeetWithList({ accounts, onAccountClick }) {
  return (
    <ul className={`${styles.accountList} ${styles.meetWithAccount}`}>
      {accounts.map((account, idx) => (
        <li
          key={account.email || `account-${idx}`}
          className={styles.accountItem}
          onClick={(e) => {
            e.stopPropagation();
            onAccountClick(account);
          }}
        >
          <div className={styles.accountImg}>
            {account.photoUrl ? <img src={account.photoUrl} alt={account.name} /> : <ProfileIcon />}
          </div>
          {account.name === account.email || !account.name ? (
            <p className={styles.accountName}>{account.email}</p>
          ) : (
            <>
              <p className={styles.accountName}>{account.name}</p>
              <p className={styles.accountEmail}>({account.email})</p>
            </>
          )}

          <div
            className={styles.meetWithBadge}
            style={{
              backgroundColor: account.isAccess ? account.color : "#484D4D",
            }}
          ></div>
          {!account.isAccess && <div className={styles.notAccess}>Calendar cannot be shown</div>}
        </li>
      ))}
    </ul>
  );
}
