import { Global } from "@emotion/react";

export const fontsTheme = {
  heading: `Pretendard Variable, sans-serif`,
  body: `Pretendard Variable, sans-serif`,
};

export const Fonts = () => {
  return (
    <Global
      styles={`
      @font-face {
        font-family: Montserrat Variable;
        font-weight: 45 920;
        font-style: normal;
        font-display: swap;
        src: url("./Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype-variations");
      }
      @font-face {
        font-family: Pretendard Variable;
        font-weight: 45 920;
        font-style: normal;
        font-display: swap;
        src: url("./Pretendard/variable/woff2/PretendardVariable.woff2") format("woff2-variations");
      }
      `}
    />
  );
};
