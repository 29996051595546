import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKey } from "../core/useFetchSpaceQuery";

export const deleteSpaceApi = async (
  api: ReturnType<typeof useApi>,
  projectId: string
): Promise<void> => {
  await api.delete(`/projects/${projectId}`);
};

export const useDeleteSpaceMutation = (options?: UseMutationOptions<void, AxiosError, string>) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, string>({
    mutationFn: (projectId: string) => deleteSpaceApi(api, projectId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
