import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useApi from "../../../services/auth/useApi";
import { spaceOrderQueryKey } from "./useFetchProjectOrderQuery";

interface ReorderSpacesPayload {
  projectIds: string[];
}

export const reorderSpacesApi = async (
  api: ReturnType<typeof useApi>,
  payload: ReorderSpacesPayload
): Promise<void> => {
  await api.post("/projects/order", payload);
};

export const useReorderSpacesMutation = (
  options?: UseMutationOptions<void, AxiosError, ReorderSpacesPayload>
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, ReorderSpacesPayload>({
    mutationFn: (payload) => reorderSpacesApi(api, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceOrderQueryKey() });
    },
    ...options,
  });
};
