import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";
import { SpaceCircle } from "./SpaceCircle";
import { SpaceType } from "../../../types/space";
import { SpaceMenuItemPopover } from "./SpaceMenuItemPopover";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import React, { useEffect, useState } from "react";
import { Flex, Tooltip, Text, Input, Box, Center } from "@chakra-ui/react";

import { createPortal } from "react-dom";
import FilterProjectReorderDragLayer from "../FilterProjectReorderDragLayer";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";

interface SpaceSideBarItemProps {
  data: SpaceType;
  index: number;
  isExpanded: boolean;
  isSelected: boolean;
  onArchive: () => void;
  onDelete: () => void;
  onUpdate: (payload: Partial<SpaceType>) => void;
  onClickSpaceCircle: () => void;
  onReorder: (orderId: string[]) => void;
  moveSpace: (dragIndex: number, hoverIndex: number) => void;
}

export const SpaceSideBarItem: React.FC<SpaceSideBarItemProps> = ({
  data,
  index,
  isExpanded,
  isSelected,
  onArchive,
  onDelete,
  onUpdate,
  onClickSpaceCircle,
  onReorder,
  moveSpace,
}) => {
  const { color, title } = data;
  const trackCall = useJuneTrackCall();
  const [menuVisible, setMenuVisible] = useState(false);
  const [inputValue, setInputValue] = useState(title);
  const [isEditing, setIsEditing] = useState(false);
  const [prevIndex, setPrevIndex] = useState(index);

  const { ref, isDragging, shouldShowPreview } = useDragAndDrop({
    index,
    data,
    onReorder,
    prevIndex,
    setPrevIndex,
  });

  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setMenuVisible(true);
  };

  const handleMenuClose = () => setMenuVisible(false);

  const handleRename = () => {
    setMenuVisible(false);
    setIsEditing(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInput = e.target.value;
    setInputValue(newInput);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputValue.trim() === "" || event.key !== "Enter") return;
    handleSubmitRename();
  };

  const handleSubmitRename = () => {
    if (inputValue.trim() === "") return;
    onUpdate({ title: inputValue });
    trackCall("update_space", {
      location: "space_filter",
      type: "title",
    });
    setIsEditing(false);
  };

  const handleChangeColor = (newColor: string) => {
    setMenuVisible(false);
    onUpdate({ color: newColor });
    trackCall("update_space", {
      location: "space_filter",
      type: "color",
    });
  };

  return (
    <Box
      borderRadius={shouldShowPreview ? "6px" : "initial"}
      bg={shouldShowPreview ? "rgba(255, 255, 255, 0.04)" : "initial"}
    >
      <SpaceMenuItemPopover
        menuVisible={menuVisible}
        handleMenuClose={handleMenuClose}
        onArchive={onArchive}
        onDelete={onDelete}
        onRename={handleRename}
        onChangeColor={handleChangeColor}
        offset={isExpanded ? [25, -50] : [15, -15]}
        spaceColor={color}
      >
        <Flex
          ref={ref}
          justifyContent={"center"}
          alignItems={"center"}
          opacity={shouldShowPreview ? 0 : 1}
          cursor={"pointer"}
        >
          {isExpanded ? (
            <Flex
              w={"full"}
              h={pxToRem(36)}
              // NOTE 우클릭 시 border가 생기면서 padding 좌우상하로 1px 줄어드는 것 보정
              py={menuVisible ? pxToRem(0.5) : 1}
              px={menuVisible ? pxToRem(1) : 0.5}
              borderRadius="md"
              _hover={{
                bg: hexToRgba("#ffffff", 0.04),
              }}
              bg={menuVisible ? hexToRgba("#ffffff", 0.08) : "initial"}
              border={menuVisible ? `1px solid ${hexToRgba("#ffffff", 0.08)} ` : "none"}
              alignItems={"center"}
              onContextMenu={handleRightClick}
              cursor={"pointer"}
              onClick={onClickSpaceCircle}
            >
              <SpaceCircle
                spaceColor={color}
                isSelected={isSelected}
                onClick={onClickSpaceCircle}
              />

              {isEditing ? (
                <Input
                  border={"none"}
                  px={0}
                  h={"fit-content"}
                  bg={"transparent"}
                  _focus={{}}
                  _focusVisible={{}}
                  _active={{}}
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onBlur={handleSubmitRename}
                  autoFocus
                  ml={2}
                  fontSize={"0.875rem"}
                  color={"#D9D9D9"}
                />
              ) : (
                <Text textStyle={"body1"} color={"#D9D9D9"} noOfLines={1} ml={2}>
                  {title}
                </Text>
              )}
            </Flex>
          ) : (
            <Tooltip
              label={title}
              placement={"right"}
              isDisabled={isDragging}
              openDelay={1000}
              closeOnScroll={true}
            >
              <Center cursor={"pointer"} h={9}>
                <SpaceCircle
                  w={7}
                  h={7}
                  spaceColor={color}
                  isSelected={isSelected}
                  onClick={onClickSpaceCircle}
                  my={pxToRem(3)}
                  bg={menuVisible ? hexToRgba("#ffffff", 0.08) : "initial"}
                  border={menuVisible ? `1px solid ${hexToRgba("#ffffff", 0.08)}` : "none"}
                  onContextMenu={handleRightClick}
                  _hover={{
                    bg: hexToRgba("#ffffff", 0.04),
                  }}
                />
              </Center>
            </Tooltip>
          )}
        </Flex>
      </SpaceMenuItemPopover>
      {shouldShowPreview &&
        createPortal(
          <div style={{ cursor: "pointer" }}>
            <FilterProjectReorderDragLayer />
          </div>,
          document.body
        )}
    </Box>
  );
};
