import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuProps,
  SimpleGrid,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { pxToRem } from "../../../utils/styles/size";
import { PROJECT_MODAL_LIST_COLOR } from "../../../constants";
import { hexToRgba } from "../../../utils/styles/color";
import { SpaceColorCircle } from "./SpaceColorCircle";

interface SpaceMenuItemPopoverProps extends MenuProps {
  children: React.ReactNode;
  menuVisible: boolean;
  deleteLabel?: string;
  duplicateLabel?: string;
  handleMenuClose: () => void;
  onArchive?: () => void;
  onDelete?: () => void;
  onRename?: () => void;
  onDuplicate?: () => void;
  onChangeColor?: (newColor: string) => void;
  spaceColor?: string;
}

export const SpaceMenuItemPopover: React.FC<SpaceMenuItemPopoverProps> = ({
  children,
  menuVisible,
  handleMenuClose,
  onArchive,
  onDelete,
  deleteLabel,
  duplicateLabel,
  onRename,
  onDuplicate,
  onChangeColor,
  spaceColor,
  ...props
}) => {
  if (!menuVisible) return children;
  const showColorPallete = !!onChangeColor && !!spaceColor;

  return (
    <>
      <Menu isOpen={menuVisible} onClose={handleMenuClose} placement={"right-start"} {...props}>
        <MenuButton w={"full"}>{children}</MenuButton>
        <MenuList
          w={pxToRem(188)}
          minW={"initial"}
          bg={"#2F3131"}
          borderRadius={6}
          py={0}
          border={`1px solid #545959`}
          boxSizing={"border-box"}
        >
          {onChangeColor && spaceColor && (
            <>
              <SimpleGrid p={2.5} columns={8} spacing={2}>
                {PROJECT_MODAL_LIST_COLOR.map((colorValue) => {
                  return (
                    <SpaceColorCircle
                      key={colorValue}
                      colorValue={colorValue}
                      spaceColor={spaceColor}
                      onClick={() => onChangeColor(colorValue)}
                    />
                  );
                })}
              </SimpleGrid>
              <MenuDivider my={0.5} />
            </>
          )}

          {onRename && (
            <MenuItem
              {...commonMenuItemStyles(showColorPallete)}
              isDisabled={!onRename}
              boxSizing={"border-box"}
              onClick={onRename}
            >
              <Text textStyle={"body3"}>Rename</Text>
            </MenuItem>
          )}
          {onArchive && (
            <MenuItem
              {...commonMenuItemStyles(showColorPallete)}
              boxSizing={"border-box"}
              isDisabled={!onArchive}
              onClick={onArchive}
            >
              <Text textStyle={"body3"}>Archived</Text>
            </MenuItem>
          )}

          {onDuplicate && (
            <MenuItem
              {...commonMenuItemStyles(showColorPallete)}
              isDisabled={!onDuplicate}
              boxSizing={"border-box"}
              onClick={onDuplicate}
            >
              <Text textStyle={"body3"}>{duplicateLabel ?? "Duplicate"}</Text>
            </MenuItem>
          )}

          {onDelete && (
            <MenuItem
              {...commonMenuItemStyles(showColorPallete)}
              isDisabled={!onDelete}
              boxSizing={"border-box"}
              onClick={onDelete}
            >
              <Text textStyle={"body3"}>{deleteLabel ?? "Delete"}</Text>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

const commonMenuItemStyles = (hasPallete: boolean) => {
  return {
    bg: "#2F3131",
    color: "white",
    _hover: {
      bg: hexToRgba("#ffffff", 0.04),
    },
    _focus: {
      outline: "none",
      boxShadow: "none",
    },
    _first: {
      borderTopRadius: hasPallete ? 0 : 6,
    },
    _last: {
      borderBottomRadius: 6,
    },
    _only: {
      borderRadius: 6,
    },
  };
};
