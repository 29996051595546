import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";

interface SideBarContainerProps extends FlexProps {
  children?: ReactJSXElement | ReactJSXElement[];
  isExpanded?: boolean;
}
export const SideBarContainer: React.FC<SideBarContainerProps> = ({
  isExpanded = true,
  children,
  ...props
}) => {
  return (
    <Flex
      flexShrink={0}
      flexDir={"column"}
      as="nav"
      h={"full"}
      color="white"
      width={isExpanded ? pxToRem(250) : pxToRem(48)}
      transition="width 0.3s ease-in-out"
      bg={"#2f3131"}
      {...props}
    >
      {children}
    </Flex>
  );
};
