import { atom } from "recoil";

export const taskState = atom({
  key: "taskState",
  default: null,
});

/**
 * 만들어진 Project의 Color를 저장 하는 변수
 * 프로젝트를 새로 만들경우에 배열에 없는 Color를 만든다.
 * 모든 컬러를 사용시 맨위의 Color부터 다시 사용한다.
 */

export const meetWithAccountsState = atom({
  key: "meetWithAccountsState",
  default: [],
});
