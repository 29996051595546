import React from "react";
import { Flex } from "@chakra-ui/react";
import { SideBarToggleButton } from "./SideBarToggleButton";
import { pxToRem } from "../../../utils/styles/size";
import { LogoIcon } from "../../../icons";

interface SideBarTitleSectionProps {
  isExpanded: boolean;
  onClick: () => void;
}
export const SideBarTitleSection: React.FC<SideBarTitleSectionProps> = ({
  onClick,
  isExpanded,
}) => {
  return (
    <Flex justify={"space-between"} h={pxToRem(36)} px={isExpanded ? 1 : 0} alignItems={"center"}>
      <LogoIcon display={isExpanded ? "block" : "none"} w={pxToRem(50)} h={2.5} />
      <SideBarToggleButton onClick={onClick} isExpanded={isExpanded} />
    </Flex>
  );
};
