import { atom, selector } from "recoil";
import { LocalStoragePrefix } from "../../constants/storage";
import { localStorageEffect } from "../localStorage/localStorageEffect";
import { NoSpaceFilter } from "../../constants/space";
import { projectListState } from "../projects/projectListState";
import { getActiveSpaceIds, getAllSpaceIds } from "../../services/space/space.service";

const localStorageKey = `${LocalStoragePrefix}_selectedSpaceIdListState`;

/* atom */
export const selectedSpaceIdListState = atom<(string | typeof NoSpaceFilter)[] | null>({
  key: "selectedSpaceIdListState",
  default: null,
  effects: [localStorageEffect<(string | typeof NoSpaceFilter)[] | null>(localStorageKey, null)],
});

/* all selectedSpace selector */
export const selectedSpaceIdListStateSelector = selector({
  key: "selectedSpaceIdListStateSelector",
  get: ({ get }) => {
    const selectedSpaces = get(selectedSpaceIdListState);
    const projectList = get(projectListState) || [];
    if (selectedSpaces) {
      return selectedSpaces;
    }
    const allSpaceIds = getAllSpaceIds(projectList);
    return [...allSpaceIds, NoSpaceFilter];
  },
});

/* in progress selectedSpace selector */
export const inProgressSelectedSpaceIdListSelector = selector({
  key: "inProgressSelectedSpaceIdListSelector",
  get: ({ get }) => {
    const selectedSpaceIds = get(selectedSpaceIdListStateSelector);
    const projectList = get(projectListState) || [];
    const activeSpaceIds = getActiveSpaceIds(selectedSpaceIds, projectList);
    return Array.from(activeSpaceIds);
  },
});
