import { forwardRef } from "react";
import { IconButton } from "@chakra-ui/react";
import { hexToRgba } from "../../utils/styles/color";
import { pxToRem } from "../../utils/styles/size";
import { MoreIcon } from "../../icons";

interface MoreButtonProps {
  openMore: boolean;
  onClick: () => void;
}

export const MoreButton = forwardRef<HTMLButtonElement, MoreButtonProps>(
  ({ openMore, onClick }, ref) => {
    return (
      <IconButton
        icon={<MoreIcon />}
        aria-label="Show options"
        bg={openMore ? `${hexToRgba("#ffffff", 0.14)}` : "transparent"}
        onClick={onClick}
        ref={ref}
        w={pxToRem(26)}
        h={pxToRem(26)}
        _hover={{
          bg: hexToRgba("#ffffff", 0.14),
        }}
        color={"#abadad"}
        boxSizing={"border-box"}
        _active={{}}
        variant="outline"
        fontSize={pxToRem(16)}
        borderRadius={pxToRem(5)}
        border={"1px solid"}
        borderColor={hexToRgba("#ffffff", 0.14)}
        minWidth={"initial"}
      />
    );
  }
);
