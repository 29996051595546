import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import styles from "./InboxReorderDragLayer.module.css";
import { projectListState } from "../../recoil/projects/projectListState";
import { getSpaceColor } from "../../services/space/space.service";
const { useDragLayer } = require("react-dnd");

function InboxReorderDragLayer() {
  const { item, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (!isDragging) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [isDragging]);

  const [projectList] = useRecoilState(projectListState);
  const integrationData = item?.integration?.provider;

  const [integrationImg, setIntegrationImg] = useState();

  useEffect(() => {
    if (integrationData && integrationData !== "") {
      setIntegrationImg(integrationData);
    }
  }, [integrationData]);
  const { projectId } = item;
  const style = useMemo(
    () => ({
      backgroundColor: `${getSpaceColor(
        projectList,
        projectId
      )}${item?.itemStatus === "Completed" ? "" : "33"} `,
      boxShadow: `0 0 0 1px ${getSpaceColor(projectList, projectId)} inset`,
    }),
    [item?.itemStatus, projectId, projectList]
  );

  const layerStyles = useMemo(
    () => ({
      position: "fixed",
      pointerEvents: "none",
      zIndex: 1000,
      left: 0,
      top: 0,
      transform: currentOffset
        ? `translate(${currentOffset.x}px, ${currentOffset.y}px)`
        : undefined,
      width: "264px",
      height: "36px",
      borderRadius: "6px",
      border: "1px solid var(--hover, rgba(255, 255, 255, 0.08))",
      background:
        "linear-gradient(0deg, var(--hover-big, rgba(255, 255, 255, 0.04)) 0%, var(--hover-big, rgba(255, 255, 255, 0.04)) 100%), #242626",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "8px",
      boxSizing: "border-box",
      cursor: "pointer",
    }),
    [currentOffset]
  );

  if (!isVisible || !item || (item.dragType !== "inbox" && item.dragType !== "integrationDrag")) {
    return null;
  }

  return (
    <div style={layerStyles} key={{ title: "task title" }} className={styles.taskBody}>
      <div
        className={`${styles["taskList-body-icon"]} ${
          item?.itemStatus === "Completed" ? styles["taskList-body-icon-done"] : ""
        }`}
        style={style}
      >
        <div className={styles["body-icon-hover"]}></div>
      </div>
      <div className={styles["taskList-body-title"]}>{item.title}</div>
      {integrationImg && (
        <div
          className={`${styles["integration"]} ${
            integrationImg === "gmail"
              ? styles["integration-gmail"]
              : integrationImg === "jira"
                ? styles["integration-jira"]
                : integrationImg === "slack" && styles["integration-slack"]
          } `}
        ></div>
      )}
    </div>
  );
}

export default React.memo(InboxReorderDragLayer);
