import { useEffect, useRef } from "react";
import TrashPopup from "./TrashPopup";

interface TrashPopupWrapperProps {
  isOpen: boolean;
  onClose: () => void;
  toggleButtonRef: React.RefObject<HTMLButtonElement>;
}

export const TrashPopupWrapper: React.FC<TrashPopupWrapperProps> = ({
  isOpen,
  onClose,
  toggleButtonRef,
}) => {
  const trashPopupRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (
        trashPopupRef.current &&
        !trashPopupRef.current.contains(target) &&
        (!toggleButtonRef.current || !toggleButtonRef.current.contains(target))
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose, toggleButtonRef]);

  return (
    <div>
      <div ref={trashPopupRef}>{isOpen && <TrashPopup onClose={onClose} />}</div>
    </div>
  );
};
