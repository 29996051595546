import { useMutation } from "@tanstack/react-query";
import useApi from "../services/auth/useApi";
import useFetchCalendarEvents from "../hooks/useFetchCalendarEvents";

import { deleteNullValueInObject } from "../utils/taskDetail/formatServerSendData";

interface UpdateRecurrenceBlockApiProps {
  updatedBlockData: any;
  selectedOption: "current" | "from" | "all";
  notify?: boolean;
  creator: string;
}

export const useUpdateRecurrenceBlock = () => {
  const api = useApi();
  const { invalidateCalendarEvents } = useFetchCalendarEvents();

  const updateRecurrenceBlock = async ({
    updatedBlockData,
    selectedOption,
    notify,
    creator,
  }: UpdateRecurrenceBlockApiProps): Promise<void> => {
    // TODO 추후 eventType으로 변경
    const nullDeletedData = deleteNullValueInObject(updatedBlockData);

    const meetingCode = updatedBlockData.hangoutLink?.replace("https://meet.google.com/", "");
    const finalizedBlockData = updatedBlockData.hangoutLink
      ? { ...nullDeletedData, meetingCode }
      : nullDeletedData;

    await api.patch(`tasks/recurrence`, finalizedBlockData, {
      params: {
        eventId: finalizedBlockData.id,
        option: selectedOption,
        ...(notify ? { notification: notify } : {}),
      },
      headers: { "X-Requester": creator },
    });
  };

  return useMutation<void, Error, UpdateRecurrenceBlockApiProps>({
    mutationFn: updateRecurrenceBlock,
    onSuccess: () => {
      invalidateCalendarEvents();
    },
  });
};
