import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { spaceQueryKey } from "../core/useFetchSpaceQuery";

export const deleteSpacesApi = async (
  api: ReturnType<typeof useApi>,
  projectIds: string[]
): Promise<void> => {
  await api.delete("/projects", {
    data: {
      projects: projectIds,
    },
  });
};

export const useDeleteSpacesMutation = (
  options?: UseMutationOptions<void, AxiosError, string[]>
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, string[]>({
    mutationFn: (projectIds: string[]) => deleteSpacesApi(api, projectIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
    },
    ...options,
  });
};
