import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createPortal } from "react-dom";
import { useQueryClient } from "@tanstack/react-query";

import { useRecoilState, useRecoilCallback, useSetRecoilState } from "recoil";
import { tokenState } from "../recoil/auth/tokenState";
import { calendarEventState, calendarViewBaseDateState } from "../recoil/calendar/calendarState";
import { taskPopupState } from "../recoil/taskDetail/taskPopupState";
import { toastState } from "../recoil/toast/toastState";

import { useJuneIdentifyCall, useJuneTrackCall } from "../utils/june/analytics";
import { loadFromLocalStorage, saveToLocalStorage } from "../utils/localStorage/localStorage";
import { capitalizeFirstLetter } from "../utils/common/stringFormat/capitalizeFirstLetter";

import useFetchCompletedTasks from "../hooks/useFetchCompletedTasks";
import useFetchInProgressTasks from "../hooks/useFetchInProgressTasks";
import useFetchCalendarEvents from "../hooks/useFetchCalendarEvents";

import { CalendarViewType } from "../constants";
import { taskDetailHeightState } from "../recoil/taskDetail/taskDetailHeightState";

import TaskInbox from "../compoenets/TaskInbox";
import Integration from "../compoenets/Integration";
import CalendarCustom from "../compoenets/CalendarCustom";
import useApi from "../services/auth/useApi";
import useApiCalendar from "../services/calendar/useApiCalendar";
import Settings from "../compoenets/Settings";
import Wiki from "../compoenets/Wiki";
import ProjectCreate from "../compoenets/Wiki/ProjectCreate";
import Onboarding from "../compoenets/Onboarding";
import TaskDetail from "./TaskDetail";
import Toast from "./Toast";
import GuestPopup from "./Popup/GuestPopup/GuestPopup";
import RecurringPopup from "./Popup/RecurringPopup/RecurringPopup";

import styles from "./Main.module.css";
import Notice from "./Notice";
import { SpaceSideBar } from "../compoenets/ProjectFilter/SideBarMenu/SpaceSideBar";
import { viewModeState, ViewModeType } from "../recoil/view/viewMode";
import { useDisclosure } from "@chakra-ui/react";
import { accountState } from "../recoil/account/accountStateV2";
import { spaceQueryKey, useFetchSpaceQuery } from "../react-query/space/core/useFetchSpaceQuery";

const isDev = process.env.NODE_ENV !== "production";

export default function Main() {
  const navigate = useNavigate();
  const location = useLocation();

  const [token, setToken] = useRecoilState(tokenState);
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [calendarViewBaseDate, setCalendarViewBaseDate] = useRecoilState(calendarViewBaseDateState);
  const [showSettingsPopup, setShowSettingsPopup] = useState(null);

  const api = useApi();
  const calendarApi = useApiCalendar();

  const [showCreateProejct, setShowCreateProject] = useState({
    isShow: false,
    projectInfo: null,
  });
  const [viewMode] = useRecoilState(viewModeState);
  const isCalendarMode = viewMode === "calendar";
  const [showOnboarding, setShowOnboarding] = useState(null);
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false);

  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const setToast = useSetRecoilState(toastState);

  const [inboxExpand, setInboxExpand] = useState(false);
  const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(false);
  const [isIntegrationDataFirstLoading, setIsIntegrationDataFirstLoading] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [mobaEventList, setMobaEventList] = useState([]);

  const identifyCall = useJuneIdentifyCall();
  const trackCall = useJuneTrackCall();

  const { fetchCompletedTasks } = useFetchCompletedTasks();
  const { fetchInProgressTasks } = useFetchInProgressTasks();
  const fetchProjectsData = () => {
    queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
  };

  /** disclosures */
  const {
    isOpen: isSpaceSidebarExpanded,
    onToggle: onToggleSpaceSidebar,
    onOpen: onOpenSpaceSidebar,
  } = useDisclosure();

  const tokenRef = useRef(token);
  const inboxRef = useRef(null);
  const integrationBtnRef = useRef(null);
  const guestPopupRef = useRef(null);
  const recurringPopupRef = useRef(null);

  const queryClient = useQueryClient();

  const {
    query: { data: calendarEvents, isLoading: isCalendarLoading, error: calendarError },
  } = useFetchCalendarEvents();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const integrationType = searchParams.get("integrationType");

    if (integrationType) {
      const capitalizedIntegrationType = capitalizeFirstLetter(integrationType);

      // URL을 /main으로 변경
      navigate("/main", { replace: true });

      // 로컬 스토리지에서 lastSelectedIntegration 객체를 가져와 isValidToken만 삭제
      const lastSelectedIntegration = JSON.parse(localStorage.getItem("lastSelectedIntegration"));
      if (lastSelectedIntegration) {
        delete lastSelectedIntegration.isValidToken;
        localStorage.setItem("lastSelectedIntegration", JSON.stringify(lastSelectedIntegration));
      }
      trackCall("reconnect_integration", { type: integrationType });

      setToast({
        type: capitalizedIntegrationType,
        isVisible: true,
        message: `${capitalizedIntegrationType} connected`,
      });
    }
  }, [location, setToast, navigate]);

  useEffect(() => {
    // 첫 로드 시에만 실행
    if (!initialLoadDone) {
      loadData(true, true, true);
      setInitialLoadDone(true);
    }
  }, []);

  useEffect(() => {
    if (initialLoadDone) {
      loadData(true, true, true);
    }
  }, []);

  useEffect(() => {
    if (isCalendarMode && initialLoadDone) {
      loadData(false, false, true);
    }
  }, [isCalendarMode]);

  // NOTE 처음 calendar 클라이언트 상태 설정
  useEffect(() => {
    if (!isCalendarLoading && calendarEvents) {
      updateEvent(calendarEvents);
    }
  }, [isCalendarLoading, calendarEvents]);

  const handleShowCreateProject = (isShow, projectInfo) => {
    setShowCreateProject({ isShow: isShow, projectInfo: projectInfo });
  };

  const toggleSettingsPopup = (settingState) => {
    setShowSettingsPopup(settingState);
  };

  const toggleIntegrationModal = () => {
    setIsIntegrationModalOpen((prev) => !prev);
  };

  const updateEvent = useRecoilCallback(({ snapshot, set }) => async (newEvent) => {
    const currentEvent = await snapshot.getPromise(calendarEventState);
    const removedEvent = currentEvent.filter((currentItem) =>
      newEvent.some((newItem) => newItem.id === currentItem.id)
    );

    const updatedEvents = removedEvent.map((currentItem) => {
      const matchingNewEvent = newEvent.find((newItem) => newItem.id === currentItem.id);
      if (matchingNewEvent && JSON.stringify(matchingNewEvent) !== JSON.stringify(currentItem)) {
        return matchingNewEvent;
      }
      return currentItem;
    });

    const newEvents = newEvent.filter(
      (newItem) => !removedEvent.some((currentItem) => currentItem.id === newItem.id)
    );
    set(calendarEventState, [...updatedEvents, ...newEvents]);
  });

  const loadDoneTask = () => {
    fetchCompletedTasks();
  };

  const loadInboxTask = () => {
    fetchInProgressTasks();
  };

  const loadProjects = () => {
    fetchProjectsData();
  };

  const loadData = async () => {
    try {
      const res = await api.get("accounts/me");
      if (!isDev && res.data.accountInfo.membership === "trial") {
        return navigate("/joinWaitList");
      }

      handleAccountInfo(res.data);
      loadTasks();
      // await refreshTokensAndLoadCalendar(res.data, queryClient);
    } catch (err) {
      console.error("Failed to load data:", err);
    }
  };

  // NOTE 계정 정보 가져오기
  const handleAccountInfo = (data) => {
    const { accountInfo } = data;
    const primaryAccountInfo = accountInfo.accounts.find((acc) => acc.type === "primary");

    identifyCall(primaryAccountInfo.email, accountInfo.membership);
    setAccountData(data);
    setShowOnboarding(accountInfo.onboarding);
  };

  const loadTasks = () => {
    loadDoneTask();
    loadInboxTask();
    loadProjects();
  };

  // NOTE token refresh 및 캘린더 데이터 다시 가져오기
  // const refreshTokensAndLoadCalendar = async (data, queryClient) => {
  //   for (const account of data.accountInfo.accounts) {
  //     try {
  //       const refreshRes = await api.post(
  //         "auth/oauth2/refresh",
  //         {
  //           kind: "account",
  //           key: account.email,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token.mAccessToken}`,
  //           },
  //         }
  //       );
  //       setToken({
  //         ...token,
  //         accessToken: refreshRes.data.accessToken,
  //       });

  //       queryClient.invalidateQueries(["calendarEvents"]);
  //     } catch (err) {
  //       console.error("Error refreshing token or loading calendar:", err);
  //       if (err.response && err.response.status === 500) {
  //         navigate("/join");
  //       }
  //     }
  //   }
  // };

  const handleDeleteSpace = (spaceId) => {
    const newMobaEventList = mobaEventList.map((event) => {
      if (event && event.projectId && event.projectId === spaceId) {
        return { ...event, projectId: null };
      }
      return event;
    });
    setMobaEventList(newMobaEventList);
  };

  useEffect(() => {
    tokenRef.current = token;
  }, [token, setToken]);

  const handleOnboardingClick = () => {
    setShowOnboarding(true);
  };

  useEffect(() => {
    if (!loadFromLocalStorage("calendarViewType")) {
      saveToLocalStorage("calendarViewType", CalendarViewType.WEEK.type);
    }
  }, []);

  // NOTE 캘린더 이벤트 다시 가져오기
  const refetchCalendarEvents = useCallback(() => {
    queryClient.invalidateQueries(["calendarEvents"]);
  }, [queryClient, calendarViewBaseDate]);

  useEffect(() => {
    refetchCalendarEvents();
  }, [calendarViewBaseDate]);

  return (
    <>
      <div className={styles["main"]}>
        {showOnboarding === false && <Onboarding hideOnboarding={handleOnboardingClick} />}

        <SpaceSideBar
          isExpanded={isSpaceSidebarExpanded}
          onToggle={onToggleSpaceSidebar}
          onOpen={onOpenSpaceSidebar}
          onClickSettings={() => toggleSettingsPopup("Account")}
          onDeleteSpace={handleDeleteSpace}
        />

        {viewMode === "calendar" && (
          <div ref={inboxRef}>
            <TaskInbox
              loadData={loadData}
              loadDoneTask={loadDoneTask}
              loadInboxTask={loadInboxTask}
              toggleIntegrationModal={toggleIntegrationModal}
              inboxExpand={inboxExpand}
              setInboxExpand={setInboxExpand}
              setIsIntegrationModalOpen={setIsIntegrationModalOpen}
              isIntegrationModalOpen={isIntegrationModalOpen}
              integrationBtnRef={integrationBtnRef}
              setIsIntegrationDataFirstLoading={setIsIntegrationDataFirstLoading}
              setMobaEventList={setMobaEventList}
            />
          </div>
        )}

        <div className={styles["body"]}>
          {showCreateProejct.isShow === true && !isCalendarMode && (
            <ProjectCreate
              onDismissClick={handleShowCreateProject}
              loadData={loadData}
              projectDetail={showCreateProejct.projectInfo}
            />
          )}
          {isCalendarMode ? (
            <CalendarCustom
              loadData={loadData}
              // taskDetailRef={taskDetailRef}
              mobaEventList={mobaEventList}
              setMobaEventList={setMobaEventList}
            />
          ) : (
            <Wiki onProjectAddClick={handleShowCreateProject} loadData={loadData} />
          )}
        </div>
        {showSettingsPopup && (
          <Settings
            settingState={showSettingsPopup}
            onClose={() => toggleSettingsPopup(null)}
            loadData={loadData}
          />
        )}
      </div>
      {isIntegrationModalOpen && (
        <Integration
          inboxRef={inboxRef}
          settingState={(settingState) => toggleSettingsPopup(settingState)}
          isIntegrationModalOpen={isIntegrationModalOpen}
          setIsIntegrationModalOpen={setIsIntegrationModalOpen}
          integrationBtnRef={integrationBtnRef}
          isIntegrationDataFirstLoading={isIntegrationDataFirstLoading}
        />
      )}
      {taskDetail.isVisible &&
        createPortal(
          <TaskDetail
            initialData={taskDetail.data}
            guestPopupRef={guestPopupRef}
            recurringPopupRef={recurringPopupRef}
          />,
          document.body
        )}
      <Toast />

      <GuestPopup guestPopupRef={guestPopupRef} />
      <RecurringPopup recurringPopupRef={recurringPopupRef} />
    </>
  );
}
