import { useEffect, useState } from "react";
import { pxToRem } from "../utils/styles/size";
import { hexToRgba } from "../utils/styles/color";

export const useScrollBarStyle = (id: string) => {
  const [isScrolling, setIsScrolling] = useState(false);
  let scrollTimeout: ReturnType<typeof setTimeout>;

  const handleScroll = () => {
    if (!isScrolling) {
      setIsScrolling(true);
    }

    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
  };

  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
        clearTimeout(scrollTimeout);
      };
    }
  }, [id]);

  return { isScrolling };
};

export const customScrollbar = (isScrolling: boolean, shouldShow?: boolean) => ({
  "&::-webkit-scrollbar": {
    width: isScrolling ? pxToRem(8) : 0,
    transition: "width 0.3s ease",
    display: shouldShow ? "block" : "none",
  },
  "&::-webkit-scrollbar-thumb": {
    background: isScrolling ? hexToRgba("#ffffff", 0.16) : "transparent",
    borderRadius: pxToRem(4),
    backgroundClip: "padding-box",
    border: `${pxToRem(3)} solid transparent`,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    border: `${pxToRem(2)} solid transparent`,
  },
});
